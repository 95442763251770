import { gql } from "@apollo/client";
import { dynamicPageFragment } from "../fragments/dynamicPageFragment";

export const getDynamicPageQuery = gql`
    query getDynamicPage($id: Int!) {
        dynamicPage(id: $id) {
            ...DynamicPage
        }
    }
    ${dynamicPageFragment}
`;
