import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { DynamicPage } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { FunctionalButton } from "Components/FunctionalButton";
import { DynamicPageForm, DynamicPageFormValues } from "./DynamicPageForm";
import { DeleteForever } from "@bigfish/admin-ui/icons";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    dynamicPage: DynamicPage | null;
    isDeleteDialogVisible: boolean;
};

class DynamicPageEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        dynamicPage: null,
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const id = parseInt(this.props.match.params.id ?? "");
            const dynamicPage = await Api.getDynamicPage(id);
            if (!dynamicPage) {
                throw new Error("Nem található");
            }
            this.setState({ dynamicPage: dynamicPage ?? null, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar((error as Error).message, { variant: "error" });
            this.props.history.push(Path.dynamicPageList);
        }
    }

    private toggleDeleteDialogVisibility = () => {
        this.setState({ isDeleteDialogVisible: !this.state.isDeleteDialogVisible });
    };

    private deleteDynamicPage = async (): Promise<void> => {
        try {
            await Api.deleteDynamicPage(this.state.dynamicPage!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.dynamicpages.delete.success" }), { variant: "success" });
            this.props.history.push(Path.dynamicPageList);
        } catch (error) {
            this.props.enqueueSnackbar((error as Error).message, { variant: "error" });
        }
    };

    private onSubmit = async (values: DynamicPageFormValues, formikHelpers: FormikHelpers<DynamicPageFormValues>): Promise<boolean> => {
        try {
            const dynamicPage = await Api.updateDynamicPage(this.state.dynamicPage!.id, {
                webshop_path: values.webshop_path,
                script_url: values.script_url,
                script_entry_point: values.script_entry_point,
                is_active: values.is_active,
                meta_title: values.meta_title,
                meta_description: values.meta_description,
                og_title: values.og_title,
                og_description: values.og_description,
                og_image: values.og_image,
                zone: "website",
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.dynamicpages.edit.succeed" }), { variant: "success" });
            this.setState({ dynamicPage });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.dynamicpages.edit.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.dynamicPageList} color="inherit">
                    {I18n.formatMessage({ id: "pages.dynamicpages.breadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.dynamicpages.edit.breadcrumb" })}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        if (this.state.isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }
        if (!this.state.dynamicPage) {
            return <Redirect to={Path.dynamicPageList} />;
        }

        const initialValues: DynamicPageFormValues = {
            script_entry_point: this.state.dynamicPage.script_entry_point ?? "",
            script_url: this.state.dynamicPage.script_url,
            webshop_path: this.state.dynamicPage.webshop_path,
            is_active: this.state.dynamicPage.is_active,
            meta_title: this.state.dynamicPage.meta_title,
            meta_description: this.state.dynamicPage.meta_description,
            og_title: this.state.dynamicPage.og_title,
            og_description: this.state.dynamicPage.og_description,
            og_image: this.state.dynamicPage.og_image,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.dynamicpages.edit.title" })}
                    rightButtonsComponent={<FunctionalButton onClick={this.toggleDeleteDialogVisibility} icon={<DeleteForever />} />}
                />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false} enableReinitialize={true}>
                    {props => (
                        <>
                            <DynamicPageForm formType={FormType.edit} formProps={props} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>

                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.dynamicpages.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.dynamicpages.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.toggleDeleteDialogVisibility}
                    onFunctionClick={this.deleteDynamicPage}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(DynamicPageEditPage));
