import React from "react";
import { Switch, Route, Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { connect, DispatchProp } from "react-redux";

import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { Api } from "Api/Api";
import { AuthActions } from "Redux/actions/authActions";
import { Path } from "Utils/Path";
import "./App.css";

import { Loading } from "Components/Loading";
import PrivateRoute from "Components/Route/PrivateRoute";
import GuestRoute from "Components/Route/GuestRoute";

import LoginPage from "Pages/Login/LoginPage";
import DashboardPage from "Pages/Dashboard/DashboardPage";
import ResetPasswordPage from "Pages/ResetPassword/ResetPasswordPage";
import ForgotPasswordPage from "Pages/ForgotPassword/ForgotPasswordPage";
import MyProfilePage from "Pages/MyProfile/MyProfilePage";
import LogoutPage from "Pages/Logout/LogoutPage";
import EmailActivationPage from "Pages/EmailActivation/EmailActivationPage";
import ContentListPage from "Pages/ContentList/ContentListPage";
import AdminListPage from "Pages/AdminList/AdminListPage";
import AdminCreatePage from "Pages/Admin/AdminCreatePage";
import AdminEditPage from "Pages/Admin/AdminEditPage";
import ContentCreatePage from "Pages/Content/ContentCreatePage";
import ContentEditPage from "Pages/Content/ContentEditPage";
import DocumentListPage from "Pages/DocumentList/DocumentListPage";
import CategoryListPage from "Pages/CategoryList/CategoryListPage";
import CategoryCreatePage from "Pages/Category/CategoryCreatePage";
import CategoryEditPage from "Pages/Category/CategoryEditPage";
import GalleryListPage from "Pages/GalleryList/GalleryListPage";
import GalleryCreatePage from "Pages/Gallery/GalleryCreatePage";
import GalleryEditPage from "Pages/Gallery/GalleryEditPage";
import ProductBrandListPage from "Pages/ProductBrandList/ProductBrandListPage";
import ProductBrandEditPage from "Pages/ProductBrand/ProductBrandEditPage";
import ProductCategoryListPage from "Pages/ProductCategoryList/ProductCategoryListPage";
import ProductCategoryEditPage from "Pages/ProductCategory/ProductCategoryEditPage";
import ProductCatalogPage from "Pages/ProductCatalog/ProductCatalogPage";
import ProductEditPage from "Pages/Product/ProductEditPage";
import ProductListListPage from "Pages/ProductListList/ProductListListPage";
import ProductListCreatePage from "Pages/ProductList/ProductListCreatePage";
import BadgeListPage from "Pages/BadgeList/BadgeListPage";
import BadgeCreatePage from "Pages/Badge/BadgeCreatePage";
import BadgeEditPage from "Pages/Badge/BadgeEditPage";
import ProductListEditPage from "Pages/ProductList/ProductListEditPage";
import RoleListPage from "Pages/RoleList/RoleListPage";
import RoleCreatePage from "Pages/Role/RoleCreatePage";
import RoleEditPage from "Pages/Role/RoleEditPage";
import BannerListPage from "Pages/BannerList/BannerListPage";
import BannerCreatePage from "Pages/Banner/BannerCreatePage";
import BannerEditPage from "Pages/Banner/BannerEditPage";
import UserListPage from "Pages/UserList/UserListPage";
import UserEditPage from "Pages/User/UserEditPage";
import PersonListPage from "Pages/PersonList/PersonListPage";
import PersonCreatePage from "Pages/Person/PersonCreatePage";
import PersonEditPage from "Pages/Person/PersonEditPage";
import BenefitListPage from "Pages/BenefitList/BenefitListPage";
import BenefitCreatePage from "Pages/Benefit/BenefitCreatePage";
import BenefitEditPage from "Pages/Benefit/BenefitEditPage";
import DepartmentListPage from "Pages/DepartmentList/DepartmentListPage";
import DepartmentCreatePage from "Pages/Department/DepartmentCreatePage";
import DepartmentEditPage from "Pages/Department/DepartmentEditPage";
import DivisionListPage from "Pages/DivisionList/DivisionListPage";
import DivisionCreatePage from "Pages/Division/DivisionCreatePage";
import DivisionEditPage from "Pages/Division/DivisionEditPage";
import SuccessStoryListPage from "Pages/SuccessStoryList/SuccessStoryListPage";
import SuccessStoryCreatePage from "Pages/SuccessStory/SuccessStoryCreatePage";
import SuccessStoryEditPage from "Pages/SuccessStory/SuccessStoryEditPage";
import RedirectionCreatePage from "Pages/Redirection/RedirectionCreatePage";
import RedirectionEditPage from "Pages/Redirection/RedirectionEditPage";
import RedirectionListPage from "Pages/RedirectionList/RedirectionListPage";
import MailTemplateListPage from "Pages/MailTemplateList/MailTemplateListPage";
import MailTemplateEditPage from "Pages/MailTemplate/MailTemplateEditPage";
import PageListPage from "Pages/PageList/PageListPage";
import PageCreatePage from "Pages/Page/PageCreatePage";
import PageEditPage from "Pages/Page/PageEditPage";
import ShippingMethodListPage from "Pages/ShippingMethod/ShippingMethodListPage";
import OrderListPage from "Pages/OrderList/OrderListPage";
import OrderEditPage from "Pages/Order/OrderEditPage";
import WebsiteNotificationListPage from "Pages/WebsiteNotificationList/WebsiteNotificationListPage";
import WebsiteNotificationCreatePage from "Pages/WebsiteNotification/WebsiteNotificationCreatePage";
import WebsiteNotificationEditPage from "Pages/WebsiteNotification/WebsiteNotificationEditPage";
import StoreOrderListPage from "Pages/StoreOrderList/StoreOrderListPage";
import CalendarDaysListPage from "Pages/CalendarDaysList/CalendarDaysListPage";
import CalendarDayCreatePage from "Pages/CalendarDay/CalendarDayCreatePage";
import CalendarDayEditPage from "Pages/CalendarDay/CalendarDayEditPage";
import SystemSettingListPage from "Pages/SystemSettingList/SystemSettingListPage";
import PaymentMethodListPage from "Pages/PaymentMethod/PaymentMethodListPage";
import ProductParamListPage from "Pages/ProductParamList/ProductParamListPage";
import ProductParamEditPage from "Pages/ProductParamEdit/ProductParamEditPage";
import LogListPage from "Pages/LogList/LogListPage";
import MaintenanceListPage from "Pages/MaintenanceList/MaintenanceListPage";
import MaintenanceCreatePage from "Pages/Maintenance/MaintenanceCreatePage";
import MaintenanceEditPage from "Pages/Maintenance/MaintenanceEditPage";
import TodoListPage from "Pages/TodoList/TodoListPage";
import TodoEditPage from "Pages/Todo/TodoEditPage";
import MenuListPage from "Pages/MenuList/MenuListPage";
import MenuCreatePage from "Pages/Menu/MenuCreatePage";
import MenuEditPage from "Pages/Menu/MenuEditPage";
import ProductBundlesListPage from "Pages/ProductBundlesList/ProductBundlesListPage";
import ProductBundleEditPage from "Pages/ProductBundle/ProductBundleEditPage";
import ProductBundleCreatePage from "Pages/ProductBundle/ProductBundleCreatePage";
import ProductVariantListPage from "Pages/ProductVariantList/ProductVariantListPage";
import ProductVariantCreatePage from "Pages/ProductVariant/ProductVariantCreatePage";
import ProductVariantEditPage from "Pages/ProductVariant/ProductVariantEditPage";
import ProductGratisListPage from "Pages/ProductGratisList/ProductGratisListPage";
import ProductGratisEditPage from "Pages/ProductGratis/ProductGratisEditPage";
import RatingListPage from "Pages/RatingList/RatingListPage";
import FulfillmentListPage from "Pages/FulfillmentList/FulfillmentListPage";
import FulfillmentCreatePage from "Pages/Fulfillment/FulfillmentCreatePage";
import FulfillmentEditPage from "Pages/Fulfillment/FulfillmentEditPage";
import CacheManagementPage from "Pages/CacheManagement/CacheManagementPage";
import CampaignListPage from "Pages/CampaignList/CampaignListPage";
import CampaignCreatePage from "Pages/Campaign/CampaignCreatePage";
import CampaignEditPage from "Pages/Campaign/CampaignEditPage";
import GiftCardOrderListPage from "Pages/GiftCardOrderList/GiftCardOrderListPage";
import { PermissionType } from "Api/graphql/admin/types";
import SubscriptionListPage from "Pages/SubscriptionList/SubscriptionListPage";
import SubscriptionPage from "Pages/Subscription/SubscriptionPage";
import GiftCardOrderPrizeCreatePage from "Pages/GiftCardOrder/Prize/GiftCardOrderPrizeCreatePage";
import GiftCardOrderCompanyCreatePage from "Pages/GiftCardOrder/Company/GiftCardOrderCompanyCreatePage";
import GiftCardOrderEditPage from "Pages/GiftCardOrder/GiftCardOrderEditPage";
import DynamicPageListPage from "Pages/DynamicPageList/DynamicPageListPage";
import DynamicPageCreatePage from "Pages/DynamicPage/DynamicPageCreatePage";
import DynamicPageEditPage from "Pages/DynamicPage/DynamicPageEditPage";

type ReduxProps = {
    isLoggedIn: boolean;
    isStoreOnlyAdmin: boolean;
};

type Props = ReduxProps & DispatchProp & RouteComponentProps;

type State = {
    getSessionIntervalId: NodeJS.Timeout | null;
    isLoading: boolean;
};

class App extends React.Component<Props, State> {
    public readonly state: State = {
        getSessionIntervalId: null,
        isLoading: this.props.isLoggedIn,
    };

    async componentDidMount(): Promise<void> {
        if (this.state.isLoading) {
            try {
                const result = await Api.adminMe();
                this.props.dispatch(AuthActions.updateAdmin(result));
                this.setState({ isLoading: false });
            } catch (error) {
                this.setState({ isLoading: false });
            }
        }
    }

    async componentDidUpdate(prevProps: Props): Promise<void> {
        try {
            if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
                if (this.props.isLoggedIn) {
                    const getSessionIntervalId = setInterval(async () => {
                        const { token } = await Api.session();
                        this.props.dispatch(AuthActions.updateToken(token));
                    }, 3600000); // hourly

                    this.setState({ getSessionIntervalId });
                } else if (this.state.getSessionIntervalId !== null) {
                    clearInterval(this.state.getSessionIntervalId);
                }
            }
        } catch (error) {
            console.log("session query failed!");
        }
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }

        if (![Path.storeOrderList, Path.myProfile, Path.logout].includes(this.props.location.pathname) && this.props.isStoreOnlyAdmin) {
            return <Redirect to={Path.storeOrderList} />;
        }

        return (
            <Switch>
                <Route exact path={Path.login} component={LoginPage} />
                <Route exact path={Path.logout} component={LogoutPage} />
                <GuestRoute exact path={Path.forgotPassword} component={ForgotPasswordPage} />
                <GuestRoute exact path={Path.resetPassword(":data")} component={ResetPasswordPage} />
                <GuestRoute exact path={Path.emailActivation(":email", ":token")} component={EmailActivationPage} />
                <PrivateRoute exact path={Path.myProfile} component={MyProfilePage} />
                <PrivateRoute exact path={Path.adminList} component={AdminListPage} requiredPermissions={[PermissionType.admin_read]} />
                <PrivateRoute exact path={Path.adminCreate} component={AdminCreatePage} requiredPermissions={[PermissionType.admin_update]} />
                <PrivateRoute exact path={Path.adminEdit(":id")} component={AdminEditPage} requiredPermissions={[PermissionType.admin_update]} />
                <PrivateRoute exact path={Path.roleList} component={RoleListPage} requiredPermissions={[PermissionType.role_read]} />
                <PrivateRoute exact path={Path.roleCreate} component={RoleCreatePage} requiredPermissions={[PermissionType.role_update]} />
                <PrivateRoute exact path={Path.roleEdit(":id")} component={RoleEditPage} requiredPermissions={[PermissionType.role_update]} />
                <PrivateRoute exact path={Path.contentList} component={ContentListPage} requiredPermissions={[PermissionType.content_read]} />
                <PrivateRoute exact path={Path.contentCreate} component={ContentCreatePage} requiredPermissions={[PermissionType.content_update]} />
                <PrivateRoute exact path={Path.contentEdit(":id")} component={ContentEditPage} requiredPermissions={[PermissionType.content_update]} />
                <PrivateRoute exact path={Path.documentList} component={DocumentListPage} requiredPermissions={[PermissionType.content_read]} />
                <PrivateRoute exact path={Path.categoryList} component={CategoryListPage} requiredPermissions={[PermissionType.content_read]} />
                <PrivateRoute exact path={Path.categoryCreate} component={CategoryCreatePage} requiredPermissions={[PermissionType.content_update]} />
                <PrivateRoute exact path={Path.categoryEdit(":id")} component={CategoryEditPage} requiredPermissions={[PermissionType.content_update]} />
                <PrivateRoute exact path={Path.productCatalog} component={ProductCatalogPage} requiredPermissions={[PermissionType.product_read]} />
                <PrivateRoute exact path={Path.productEdit(":id")} component={ProductEditPage} requiredPermissions={[PermissionType.product_update]} />
                <PrivateRoute exact path={Path.categoryList} component={CategoryListPage} requiredPermissions={[PermissionType.content_read]} />
                <PrivateRoute exact path={Path.categoryEdit(":id")} component={CategoryEditPage} requiredPermissions={[PermissionType.content_update]} />
                <PrivateRoute exact path={Path.productBrandList} component={ProductBrandListPage} requiredPermissions={[PermissionType.product_brand_read]} />
                <PrivateRoute exact path={Path.productBrandEdit(":id")} component={ProductBrandEditPage} requiredPermissions={[PermissionType.product_brand_update]} />
                <PrivateRoute exact path={Path.galleryList} component={GalleryListPage} requiredPermissions={[PermissionType.content_read]} />
                <PrivateRoute exact path={Path.galleryCreate} component={GalleryCreatePage} requiredPermissions={[PermissionType.content_update]} />
                <PrivateRoute exact path={Path.galleryEdit(":id")} component={GalleryEditPage} requiredPermissions={[PermissionType.content_update]} />
                <PrivateRoute exact path={Path.productCategoryList} component={ProductCategoryListPage} requiredPermissions={[PermissionType.product_read]} />
                <PrivateRoute exact path={Path.productCategoryEdit(":id")} component={ProductCategoryEditPage} requiredPermissions={[PermissionType.product_update]} />
                <PrivateRoute exact path={Path.productListList} component={ProductListListPage} requiredPermissions={[PermissionType.product_list_read]} />
                <PrivateRoute exact path={Path.productBundles} component={ProductBundlesListPage} requiredPermissions={[PermissionType.product_read]} />
                <PrivateRoute exact path={Path.productBundleCreate} component={ProductBundleCreatePage} requiredPermissions={[PermissionType.product_bundle_create]} />
                <PrivateRoute exact path={Path.productBundleEdit(":id")} component={ProductBundleEditPage} requiredPermissions={[PermissionType.product_bundle_update]} />
                <PrivateRoute exact path={Path.productGratisList} component={ProductGratisListPage} requiredPermissions={[PermissionType.product_read]} />
                <PrivateRoute exact path={Path.productGratisEdit(":id")} component={ProductGratisEditPage} requiredPermissions={[PermissionType.product_update]} />

                <PrivateRoute
                    exact
                    path={Path.productListCreate(":productListType")}
                    component={ProductListCreatePage}
                    requiredPermissions={[PermissionType.product_list_create]}
                />
                <PrivateRoute exact path={Path.productListEdit(":id")} component={ProductListEditPage} requiredPermissions={[PermissionType.product_list_update]} />
                <PrivateRoute exact path={Path.badgeList} component={BadgeListPage} requiredPermissions={[PermissionType.badge_read]} />
                <PrivateRoute exact path={Path.badgeCreate} component={BadgeCreatePage} requiredPermissions={[PermissionType.badge_update]} />
                <PrivateRoute exact path={Path.badgeEdit(":id")} component={BadgeEditPage} requiredPermissions={[PermissionType.badge_update]} />
                <PrivateRoute exact path={Path.bannerList} component={BannerListPage} requiredPermissions={[PermissionType.banner_read]} />
                <PrivateRoute exact path={Path.bannerCreate(":type")} component={BannerCreatePage} requiredPermissions={[PermissionType.banner_update]} />
                <PrivateRoute exact path={Path.bannerEdit(":id")} component={BannerEditPage} requiredPermissions={[PermissionType.banner_update]} />
                <PrivateRoute exact path={Path.userList} component={UserListPage} requiredPermissions={[PermissionType.user_read]} />
                <PrivateRoute exact path={Path.userEdit(":id")} component={UserEditPage} requiredPermissions={[PermissionType.user_update]} />
                <PrivateRoute exact path={Path.personCreate} component={PersonCreatePage} requiredPermissions={[PermissionType.manage_career_persons]} />
                <PrivateRoute exact path={Path.personEdit(":id")} component={PersonEditPage} requiredPermissions={[PermissionType.manage_career_persons]} />
                <PrivateRoute exact path={Path.personList} component={PersonListPage} requiredPermissions={[PermissionType.manage_career_persons]} />
                <PrivateRoute exact path={Path.benefitCreate} component={BenefitCreatePage} requiredPermissions={[PermissionType.manage_career_benefits]} />
                <PrivateRoute exact path={Path.benefitEdit(":id")} component={BenefitEditPage} requiredPermissions={[PermissionType.manage_career_benefits]} />
                <PrivateRoute exact path={Path.benefitList} component={BenefitListPage} requiredPermissions={[PermissionType.manage_career_benefits]} />
                <PrivateRoute exact path={Path.departmentCreate} component={DepartmentCreatePage} requiredPermissions={[PermissionType.manage_career_departments]} />
                <PrivateRoute exact path={Path.departmentEdit(":id")} component={DepartmentEditPage} requiredPermissions={[PermissionType.manage_career_departments]} />
                <PrivateRoute exact path={Path.departmentList} component={DepartmentListPage} requiredPermissions={[PermissionType.manage_career_departments]} />
                <PrivateRoute exact path={Path.divisionCreate} component={DivisionCreatePage} requiredPermissions={[PermissionType.manage_career_divisions]} />
                <PrivateRoute exact path={Path.divisionEdit(":id")} component={DivisionEditPage} requiredPermissions={[PermissionType.manage_career_divisions]} />
                <PrivateRoute exact path={Path.divisionList} component={DivisionListPage} requiredPermissions={[PermissionType.manage_career_divisions]} />
                <PrivateRoute exact path={Path.successStoryCreate} component={SuccessStoryCreatePage} requiredPermissions={[PermissionType.manage_career_success_stories]} />
                <PrivateRoute exact path={Path.successStoryEdit(":id")} component={SuccessStoryEditPage} requiredPermissions={[PermissionType.manage_career_success_stories]} />
                <PrivateRoute exact path={Path.successStoryList} component={SuccessStoryListPage} requiredPermissions={[PermissionType.manage_career_success_stories]} />
                <PrivateRoute exact path={Path.redirectionCreate(":type")} component={RedirectionCreatePage} requiredPermissions={[PermissionType.manage_redirections]} />
                <PrivateRoute exact path={Path.redirectionEdit(":id")} component={RedirectionEditPage} requiredPermissions={[PermissionType.manage_redirections]} />
                <PrivateRoute exact path={Path.redirectionList} component={RedirectionListPage} requiredPermissions={[PermissionType.manage_redirections]} />
                <PrivateRoute exact path={Path.mailTemplateList} component={MailTemplateListPage} requiredPermissions={[PermissionType.mail_template_read]} />
                <PrivateRoute exact path={Path.mailTemplateEdit(":id")} component={MailTemplateEditPage} requiredPermissions={[PermissionType.mail_template_update]} />
                <PrivateRoute exact path={Path.pageList} component={PageListPage} requiredPermissions={[PermissionType.content_read]} />
                <PrivateRoute exact path={Path.pageCreate} component={PageCreatePage} requiredPermissions={[PermissionType.content_update]} />
                <PrivateRoute exact path={Path.pageEdit(":id")} component={PageEditPage} requiredPermissions={[PermissionType.content_update]} />
                <PrivateRoute
                    exact
                    path={Path.fulfillmentCreate}
                    component={FulfillmentCreatePage}
                    requiredPermissions={[PermissionType.shipping_method_read, PermissionType.shipping_method_update]}
                />
                <PrivateRoute
                    exact
                    path={Path.fulfillmentEdit(":id")}
                    component={FulfillmentEditPage}
                    requiredPermissions={[PermissionType.shipping_method_read, PermissionType.shipping_method_update]}
                />
                <PrivateRoute
                    exact
                    path={Path.fulfillmentList}
                    component={FulfillmentListPage}
                    requiredPermissions={[PermissionType.shipping_method_read, PermissionType.shipping_method_update]}
                />
                <PrivateRoute
                    exact
                    path={Path.shippingMethodEdit(":id")}
                    component={ShippingMethodListPage}
                    requiredPermissions={[PermissionType.shipping_method_read, PermissionType.shipping_method_update]}
                />
                <PrivateRoute
                    exact
                    path={Path.shippingMethodList}
                    component={ShippingMethodListPage}
                    requiredPermissions={[PermissionType.shipping_method_read, PermissionType.shipping_method_update]}
                />
                <PrivateRoute exact path={Path.orderList} component={OrderListPage} requiredPermissions={[PermissionType.order_read]} />
                <PrivateRoute exact path={Path.orderEdit(":incrementId")} component={OrderEditPage} requiredPermissions={[PermissionType.order_update]} />
                <PrivateRoute exact path={Path.subscriptionList} component={SubscriptionListPage} requiredPermissions={[PermissionType.order_read]} />
                <PrivateRoute exact path={Path.subscriptionEdit(":id")} component={SubscriptionPage} requiredPermissions={[PermissionType.order_update]} />

                <PrivateRoute exact path={Path.giftCardOrderList} component={GiftCardOrderListPage} requiredPermissions={[PermissionType.gift_card_order_update]} />
                <PrivateRoute exact path={Path.giftCardOrderPrizeCreate} component={GiftCardOrderPrizeCreatePage} requiredPermissions={[PermissionType.gift_card_order_create]} />

                <PrivateRoute
                    exact
                    path={Path.giftCardOrderCompanyCreate}
                    component={GiftCardOrderCompanyCreatePage}
                    requiredPermissions={[PermissionType.gift_card_order_create]}
                />

                <PrivateRoute exact path={Path.giftCardOrderEdit(":id")} component={GiftCardOrderEditPage} requiredPermissions={[PermissionType.gift_card_order_update]} />

                <PrivateRoute exact path={Path.storeOrderList} component={StoreOrderListPage} requiredPermissions={[PermissionType.store_read]} />
                <PrivateRoute exact path={Path.websiteNotificationList} component={WebsiteNotificationListPage} requiredPermissions={[PermissionType.website_notification_read]} />
                <PrivateRoute
                    exact
                    path={Path.websiteNotificationCreate}
                    component={WebsiteNotificationCreatePage}
                    requiredPermissions={[PermissionType.website_notification_create]}
                />
                <PrivateRoute
                    exact
                    path={Path.websiteNotificationEdit(":id")}
                    component={WebsiteNotificationEditPage}
                    requiredPermissions={[PermissionType.website_notification_update]}
                />
                <PrivateRoute exact path={Path.productParamList} component={ProductParamListPage} requiredPermissions={[PermissionType.product_read]} />
                <PrivateRoute exact path={Path.productParamEdit(":id")} component={ProductParamEditPage} requiredPermissions={[PermissionType.product_update]} />
                <PrivateRoute exact path={Path.calendarDaysList} component={CalendarDaysListPage} requiredPermissions={[PermissionType.manage_calendar]} />
                <PrivateRoute exact path={Path.calendarDayCreate} component={CalendarDayCreatePage} requiredPermissions={[PermissionType.manage_calendar]} />
                <PrivateRoute exact path={Path.calendarDayEdit(":date")} component={CalendarDayEditPage} requiredPermissions={[PermissionType.manage_calendar]} />
                <PrivateRoute exact path={Path.systemSettingsList} component={SystemSettingListPage} requiredPermissions={[PermissionType.setting_read]} />
                <PrivateRoute
                    exact
                    path={Path.paymentMethodList}
                    component={PaymentMethodListPage}
                    requiredPermissions={[PermissionType.payment_method_read, PermissionType.payment_method_update]}
                />
                <PrivateRoute
                    exact
                    path={Path.paymentMethodEdit(":id")}
                    component={PaymentMethodListPage}
                    requiredPermissions={[PermissionType.payment_method_read, PermissionType.payment_method_update]}
                />
                <PrivateRoute exact path={Path.logList} component={LogListPage} requiredPermissions={[PermissionType.log_read]} />
                <PrivateRoute exact path={Path.maintenanceList} component={MaintenanceListPage} requiredPermissions={[PermissionType.maintenance_read]} />
                <PrivateRoute exact path={Path.maintenanceCreate} component={MaintenanceCreatePage} requiredPermissions={[PermissionType.maintenance_create]} />
                <PrivateRoute exact path={Path.maintenanceEdit(":id")} component={MaintenanceEditPage} requiredPermissions={[PermissionType.maintenance_update]} />
                <PrivateRoute
                    exact
                    path={Path.productVariantList}
                    component={ProductVariantListPage}
                    requiredPermissions={[PermissionType.product_variant_create, PermissionType.product_variant_update]}
                />
                <PrivateRoute exact path={Path.productVariantCreate} component={ProductVariantCreatePage} requiredPermissions={[PermissionType.product_variant_create]} />
                <PrivateRoute exact path={Path.productVariantEdit(":id")} component={ProductVariantEditPage} requiredPermissions={[PermissionType.product_variant_update]} />

                <PrivateRoute exact path={Path.todoList} component={TodoListPage} requiredPermissions={[PermissionType.admin_todo_read]} />
                <PrivateRoute exact path={Path.todoEdit(":id")} component={TodoEditPage} requiredPermissions={[PermissionType.admin_todo_update]} />

                <PrivateRoute exact path={Path.menuList} component={MenuListPage} requiredPermissions={[PermissionType.menu_read]} />
                <PrivateRoute exact path={Path.menuCreate} component={MenuCreatePage} requiredPermissions={[PermissionType.menu_update]} />
                <PrivateRoute exact path={Path.menuEdit(":id")} component={MenuEditPage} requiredPermissions={[PermissionType.menu_update]} />
                <PrivateRoute exact path={Path.ratingList} component={RatingListPage} requiredPermissions={[PermissionType.order_rating_read]} />

                <PrivateRoute exact path={Path.cacheManagement} component={CacheManagementPage} requiredPermissions={[PermissionType.manage_cache]} />
                <PrivateRoute exact path={Path.campaignList} component={CampaignListPage} requiredPermissions={[PermissionType.content_read]} />
                <PrivateRoute exact path={Path.campaignCreate} component={CampaignCreatePage} requiredPermissions={[PermissionType.content_update]} />
                <PrivateRoute exact path={Path.campaignEdit(":id")} component={CampaignEditPage} requiredPermissions={[PermissionType.content_update]} />

                <PrivateRoute exact path={Path.dynamicPageList} component={DynamicPageListPage} requiredPermissions={[PermissionType.manage_career_divisions]} />
                <PrivateRoute exact path={Path.dynamicPageCreate} component={DynamicPageCreatePage} requiredPermissions={[PermissionType.manage_career_divisions]} />
                <PrivateRoute exact path={Path.dynamicPageEdit(":id")} component={DynamicPageEditPage} requiredPermissions={[PermissionType.manage_career_divisions]} />

                <PrivateRoute exact path={Path.dashboard} component={DashboardPage} />
                <Redirect from="*" to={Path.login} />
            </Switch>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { isLoggedIn: AuthSelectors.isLoggedIn(state.auth), isStoreOnlyAdmin: AuthSelectors.isStoreOnlyAdmin(state.auth) };
};

export default withRouter(connect(mapStateToProps)(App));
