import { gql } from "@apollo/client";
import { orderStatisticsIntervalFragment } from "../fragments/orderStatisticsIntervalFragment";

export const getOrderStatisticsQuery = gql`
    query getOrderStatisticsQuery {
        orderStatistics {
            updated
            today {
                ...OrderStatisticsInterval
            }
            month {
                ...OrderStatisticsInterval
            }
            count_unprocessed
            value_unprocessed
        }
    }
    ${orderStatisticsIntervalFragment}
`;
