import { gql } from "@apollo/client";
import { dynamicPageListItemFragment } from "../fragments/dynamicPageListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listDynamicPagesQuery = gql`
    query listDynamicPages($first: Int!, $page: Int) {
        listDynamicPages(first: $first, page: $page) {
            data {
                ...DynamicPageListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${dynamicPageListItemFragment}
    ${paginatorInfoFragment}
`;
