import { TypeSingleFilterValue, TypeColumn } from "@inovua/reactdatagrid-community/types";
import { GridView } from "Redux/reducers/gridViewReducer";

export const DataGridUtils = {
    numberFilter(filter: TypeSingleFilterValue, value: unknown): boolean {
        if (!filter.value || typeof filter.value !== "string") return true;
        if (typeof value !== "number") return false;
        switch (filter.operator) {
            case "eq":
            default:
                return `${value}` === filter.value;
        }
    },
    stringFilter(filter: TypeSingleFilterValue, value: unknown): boolean {
        if (!filter.value || typeof filter.value !== "string") return true;
        if (typeof value !== "string") return false;
        switch (filter.operator) {
            case "contains":
                return value.toLocaleLowerCase().includes(filter.value.toLocaleLowerCase());
            default:
                return value === filter.value;
        }
    },
    selectFilter(filter: TypeSingleFilterValue, value: unknown): boolean {
        if (typeof filter.value === "undefined" || filter.value === null) return true;
        switch (filter.operator) {
            case "eq":
            default:
                return `${value}` === `${filter.value}`;
        }
    },
    filter<T>(obj: T) {
        return (filter: TypeSingleFilterValue): boolean => {
            const objValue = obj[filter.name as keyof T];
            switch (filter.type) {
                case "string":
                    return DataGridUtils.stringFilter(filter, objValue);
                case "select":
                    return DataGridUtils.selectFilter(filter, objValue);
                case "number":
                    return DataGridUtils.numberFilter(filter, objValue);
            }
            return true;
        };
    },
    getGridConfigByName(initialGridConfig: GridView[], name: string) {
        return initialGridConfig.find(gridConfig => gridConfig.name === name);
    },
    getNewGridConfigByOrder(initialGridConfig: GridView[], listName: string, columns: string[]) {
        const newGridConfig = [...initialGridConfig];

        const index = newGridConfig.findIndex(gc => gc.name === listName);
        const gridView = newGridConfig[index];

        if (index !== -1) {
            newGridConfig[index] = {
                ...newGridConfig[index],
                columns: columns.map(newColumn => ({ name: newColumn, width: gridView?.columns.find(c => c.name === newColumn)?.width })),
            };
        } else {
            newGridConfig.push({ name: listName, columns: columns.map(c => ({ name: c })) });
        }

        return newGridConfig;
    },
    getNewGridConfigByWidth(initialGridConfig: GridView[], listName: string, columnName: string, width: number) {
        const newGridConfig = [...initialGridConfig];

        const gridViewIndex = newGridConfig.findIndex(gc => gc.name === listName);
        const gridView = newGridConfig[gridViewIndex];

        if (gridViewIndex !== -1) {
            // Found the list in Redux
            const columnIndex = gridView.columns.findIndex(c => c.name === columnName);
            const column = gridView.columns[columnIndex];

            if (columnIndex !== -1) {
                // Found the column too
                column.width = width;
            } else {
                // List was found, but column wasn't
                newGridConfig[gridViewIndex] = { ...newGridConfig[gridViewIndex], columns: [...newGridConfig[gridViewIndex].columns, { name: columnName, width }] };
            }
        } else {
            // List not found
            newGridConfig.push({ name: listName, columns: [{ name: columnName, width }] });
        }

        return newGridConfig;
    },
    getColumnWidth(initialGridConfig: GridView[], listName: string, columnName: string) {
        const newGridConfig = [...initialGridConfig];

        const gridViewIndex = newGridConfig.findIndex(gc => gc.name === listName);
        const gridView = newGridConfig[gridViewIndex];

        if (gridViewIndex !== -1) {
            return gridView.columns.find(c => c.name === columnName)?.width || undefined;
        }

        return undefined;
    },
    getGridConfigWithoutListName(initialGridConfig: GridView[], listName: string) {
        return initialGridConfig.filter(c => c.name !== listName);
    },
};

export type CustomTypeColumn = TypeColumn & { filterDelay?: number };
