import { gql } from "@apollo/client";

export const orderStatusesQuery = gql`
    query orderStatusesQuery($filters: OrderFilters) {
        orderStatuses(filters: $filters) {
            id
            title
            count
        }
    }
`;
