import React, { Component } from "react";
import { CircularProgress, Icon } from "@bigfish/admin-ui/core";
import { createModal, ModalProps } from "Components/createModal";
import { Constants, DeviceView } from "Utils/Constants";
import { PreviewMessage, PreviewMessageType } from "./PreviewMessage";
import "./PreviewDialog.css";
import { I18n } from "I18n/I18n";

type ComponentProps<T> = {
    isVisible: boolean;
    onClose: () => void;
    previewUrl: string;
    getPreviewData: () => T;
};

type Props<T> = ComponentProps<T>;

type State = {
    isLoading: boolean;
    error: string | null;
    deviceView: DeviceView;
    component: (props: React.PropsWithChildren<ModalProps>) => JSX.Element;
};

export class PreviewDialog<T> extends Component<Props<T>, State> {
    private iframeRef = React.createRef<HTMLIFrameElement>();

    public readonly state: State = {
        isLoading: true,
        error: null,
        deviceView: DeviceView.desktop,
        component: createModal({ isFullWidth: true, modalWidth: Constants.getPreviewWidth(DeviceView.desktop) }),
    };

    public componentDidUpdate(prevProps: Props<T>) {
        if (prevProps.isVisible && !this.props.isVisible) {
            this.setState({
                isLoading: true,
                error: null,
                deviceView: DeviceView.desktop,
            });
        }
    }

    public componentDidMount(): void {
        window.addEventListener("message", this.onMessage);
    }

    public componentWillUnmount() {
        window.removeEventListener("message", this.onMessage);
    }

    private onMessage = (event: MessageEvent<PreviewMessage>) => {
        if (event.data.type === PreviewMessageType.site_loaded) {
            if (this.iframeRef.current) {
                this.iframeRef.current.contentWindow?.postMessage(
                    { type: PreviewMessageType.set_preview_data, payload: this.props.getPreviewData() },
                    process.env.REACT_APP_WEBSITE_URL ?? ""
                );
            }
        }
        if (event.data.type === PreviewMessageType.site_preview_ready) {
            this.setState({ isLoading: false });
        }
        if (event.data.type === PreviewMessageType.site_preview_load_failed) {
            this.setState({ isLoading: false, error: event.data.payload as string });
        }
    };

    private toggleView = (deviceView: DeviceView) => () => {
        this.setState({ deviceView, error: null, isLoading: true, component: createModal({ isFullWidth: true, modalWidth: Constants.getPreviewWidth(deviceView) }) });
    };

    private renderError = () => {
        return (
            <div className="preview-error">
                <div>
                    <strong>{I18n.formatMessage({ id: "components.previewDialog.loadFailed" })}</strong>
                </div>
                <br />
                <div>{this.state.error}</div>
            </div>
        );
    };

    public render(): JSX.Element {
        const { error, deviceView, isLoading, component: PreviewDialogModal } = this.state;
        return (
            <PreviewDialogModal
                title={
                    <div>
                        <Icon
                            className={`fas fa-mobile preview-button ${deviceView === DeviceView.mobile ? "preview-active" : ""}`}
                            title={I18n.formatMessage({ id: "components.previewDialog.mobileView" })}
                            onClick={this.toggleView(DeviceView.mobile)}
                        />
                        <Icon
                            className={`fas fa-tablet preview-button ${deviceView === DeviceView.tablet ? "preview-active" : ""}`}
                            title={I18n.formatMessage({ id: "components.previewDialog.tabletView" })}
                            onClick={this.toggleView(DeviceView.tablet)}
                        />
                        <Icon
                            className={`fas fa-laptop preview-button ${deviceView === DeviceView.desktop ? "preview-active" : ""}`}
                            title={I18n.formatMessage({ id: "components.previewDialog.desktopView" })}
                            onClick={this.toggleView(DeviceView.desktop)}
                        />
                    </div>
                }
                open={this.props.isVisible}
                onClose={this.props.onClose}
            >
                <div className={`preview-container ${deviceView}`}>
                    {error && this.renderError()}
                    {!error && (
                        <iframe
                            ref={this.iframeRef}
                            width={Constants.getPreviewWidth(deviceView)}
                            height={768}
                            src={this.props.previewUrl}
                            title={I18n.formatMessage({ id: "components.previewDialog.previewTitle" })}
                            onError={() => this.setState({ isLoading: false })}
                        ></iframe>
                    )}
                    {isLoading && (
                        <div className="preview-loader">
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </PreviewDialogModal>
        );
    }
}
