import { gql } from "@apollo/client";
import { timeWIndowCostFragment } from "./timeWIndowCostFragment";
import { deliveryInformationFragment } from "./deliveryInformationFragment";

export const shippingMethodFragment = gql`
    fragment ShippingMethod on ShippingMethod {
        id
        name
        lead
        description
        weight_min
        weight_max
        cart_value_min
        order_max
        cost {
            cart_limit
            cost
        }
        cost_terminal {
            cart_limit
            cost
        }
        is_active
        is_visible
        active_from
        active_to
        created_at
        updated_at
        cost_time_windows {
            ...TimeWindowCost
        }
        delivery_information
        delivery_informations {
            ...DeliveryInformation
        }
        roles {
            vip_levels
            active_from
            active_to
        }
        time_limit_next_day_departure
    }
    ${timeWIndowCostFragment}
    ${deliveryInformationFragment}
`;
