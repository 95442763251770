import React from "react";
import { WelcomeIllustration } from "./WelcomeIllustration";
import { Box, Grid, Typography, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { PageLayout } from "Components/PageLayout";
import { Admin, getOrderStatisticsQuery_orderStatistics, PermissionType } from "Api/graphql/admin/types";
import { connect, DispatchProp } from "react-redux";
import { dashboardStyles } from "./DashboardStyles";
import { ApplicationState } from "Redux/rootReducer";
import { I18n } from "I18n/I18n";
import { Api } from "Api/Api";
import { Loading } from "Components/Loading";
import { ApiError } from "Api/ApiError";
import { WithSnackbarProps } from "notistack";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { DateFormat, DateUtils } from "Utils/DateUtils";

type ReduxProps = {
    admin: Admin;
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = WithSnackbarProps & ReduxProps & DispatchProp & WithStyles<typeof dashboardStyles>;

type State = {
    isLoading: boolean;
    stats: getOrderStatisticsQuery_orderStatistics | null;
};

class DashboardPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        stats: null,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const stats = await Api.getOrderStatisticsQuery();
            this.setState({ stats, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    }

    private renderField = (intl: string, value: number, percentDiff: number | null, isCurrency?: boolean) => {
        return (
            <tr>
                <td style={{ paddingRight: 10 }}>
                    <Typography display="inline" color="textSecondary" className={this.props.classes.text}>
                        {I18n.formatMessage({ id: `pages.dashboard.${intl}` })}
                    </Typography>
                </td>
                <td style={{ textAlign: "right" }}>
                    <Typography display="inline" className={this.props.classes.text}>
                        <strong>{I18n.formatCurrency(value, !isCurrency) + `${isCurrency ? "" : " db"}`}</strong>
                    </Typography>
                </td>
                <td>
                    {!!percentDiff && (
                        <Typography display="inline" className={this.props.classes[percentDiff > 0 ? "greenPercent" : "redPercent"] + " " + this.props.classes.text}>
                            <strong>{`${percentDiff > 0 ? "+" : ""}${percentDiff}%`}</strong>
                        </Typography>
                    )}
                </td>
            </tr>
        );
    };

    render() {
        const { stats, isLoading } = this.state;

        if (isLoading) {
            return <Loading />;
        }

        return stats ? (
            <PageLayout>
                <Grid container justify="center" alignItems="center" className={this.props.classes.container}>
                    <Grid item className={this.props.classes.content}>
                        <WelcomeIllustration />
                        <Typography variant="h1" className={this.props.classes.title}>
                            {I18n.formatMessage({ id: "pages.dashboard.welcome" }, { name: this.props.admin.name })}
                        </Typography>
                    </Grid>
                </Grid>

                {this.props.isSuperadmin || this.props.permissions.includes(PermissionType.order_stat_read) ? (
                    <div>
                        <Box mt="75px" />

                        <Grid container justify="space-evenly">
                            <Grid item>
                                <table>
                                    {this.renderField("todayCount", stats.today.count.value, stats.today.count.percent_diff)}
                                    {this.renderField("todayTotal", stats.today.total.value, stats.today.total.percent_diff, true)}
                                    {this.renderField("todayProductCount", stats.today.product_count.value, stats.today.product_count.percent_diff)}
                                    {this.renderField("todayAvg", stats.today.cart_value_avg.value, stats.today.cart_value_avg.percent_diff, true)}
                                </table>
                                <Box mt="30px" />
                            </Grid>
                            <Grid item>
                                <table>
                                    {this.renderField("monthCount", stats.month.count.value, stats.month.count.percent_diff)}
                                    {this.renderField("monthTotal", stats.month.total.value, stats.month.total.percent_diff, true)}
                                    {this.renderField("monthProductCount", stats.month.product_count.value, stats.month.product_count.percent_diff)}
                                    {this.renderField("monthAvg", stats.month.cart_value_avg.value, stats.month.cart_value_avg.percent_diff, true)}
                                </table>
                                <Box mt="30px" />
                            </Grid>
                        </Grid>

                        <Grid container justify="space-around">
                            <Grid item>
                                <table>
                                    <tr>
                                        <td style={{ paddingRight: 10 }}>
                                            <Typography display="inline" color="textSecondary" className={this.props.classes.text}>
                                                {I18n.formatMessage({ id: "pages.dashboard.unprocessed" })}
                                            </Typography>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            <Typography display="inline" className={this.props.classes.text}>
                                                <strong>
                                                    {I18n.formatCurrency(stats.count_unprocessed, true) + " db"} | {I18n.formatCurrency(stats.value_unprocessed)}
                                                </strong>
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ paddingRight: 10 }}>
                                            <Typography display="inline" color="textSecondary" className={this.props.classes.text}>
                                                {I18n.formatMessage({ id: "pages.dashboard.updated" })}
                                            </Typography>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            <Typography display="inline" className={this.props.classes.text}>
                                                <strong>{DateUtils.format(stats.updated, DateFormat.apiDateTime)}</strong>
                                            </Typography>
                                        </td>
                                    </tr>
                                </table>
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <></>
                )}
            </PageLayout>
        ) : null;
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { admin: state.auth.admin!, permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default connect(mapStateToProps)(withStyles(dashboardStyles, { withTheme: true })(DashboardPage));
