import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { DivisionFilters, DivisionListItem, DivisionSort, DivisionSortField, DynamicPageListItem } from "Api/graphql/admin/types";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Path } from "Utils/Path";
import { I18n } from "I18n/I18n";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";
import { IsActiveIcon } from "Components/IsActive";

type Props = WithSnackbarProps;

class DynamicPageListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", sortable: false, header: I18n.formatMessage({ id: "pages.dynamicPageList.grid.column.id" }), width: 132, filterDelay: Constants.filterDelayMS },
            {
                name: "webshop_path",
                header: I18n.formatMessage({ id: "pages.dynamicPageList.grid.column.webshop_path" }),
                defaultFlex: 1,
                minWidth: 200,
                sortable: false,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "script_url",
                header: I18n.formatMessage({ id: "pages.dynamicPageList.grid.column.script_url" }),
                defaultFlex: 1,
                minWidth: 200,
                sortable: false,
            },
            {
                name: "is_active",
                header: I18n.formatMessage({ id: "pages.campaignList.grid.column.is_active" }),
                defaultWidth: Constants.columnWidth.statusIcon,
                render: ({ data }: { data: DynamicPageListItem }) => <IsActiveIcon value={data.is_active} />,
                sortable: false,
            },
        ];
    };

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            name: QueryParserType.string,
            created_at: QueryParserType.dateRange,
            updated_at: QueryParserType.dateRange,
        },
        sortField: DivisionSortField,
    });

    private dataSource = async (props: DataGridParams<DivisionSort, DivisionFilters>): Promise<DataSource<DynamicPageListItem>> => {
        try {
            const result = await Api.listDynamicPages({
                first: props.limit,
                page: props.page,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.dynamicPageEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={null}
                columns={this.getColumns()}
                rowLink={this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={{}}
            />
        );
    }
}

export default withSnackbar(DynamicPageListDataGrid);
