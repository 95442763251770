import { gql } from "@apollo/client";
import { orderStatisticsItemFragment } from "./orderStatisticsItemFragment";

export const orderStatisticsIntervalFragment = gql`
    fragment OrderStatisticsInterval on OrderStatisticsInterval {
        count {
            ...OrderStatisticsItem
        }
        total {
            ...OrderStatisticsItem
        }
        product_count {
            ...OrderStatisticsItem
        }
        cart_value_avg {
            ...OrderStatisticsItem
        }
    }
    ${orderStatisticsItemFragment}
`;
