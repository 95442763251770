import React, { useState } from "react";
import { I18n } from "I18n/I18n";
import { DefaultModal } from "@bigfish/admin-ui/components";
import { Box, Button, InputAdornment, TextField, Typography } from "@bigfish/admin-ui/core";
import { EditedSetting } from "./SystemSettingListDataGrid";
import { SettingType } from "Api/graphql/admin/types";
import { NumberUtils } from "Utils/NumberUtils";
import { EventBusy } from "@bigfish/admin-ui/icons";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { StringUtils } from "Utils/StringUtils";

type Props = {
    isVisible: boolean;
    onClose: () => void;
    editedSetting: EditedSetting;
    onSaveClick: (id: string, value: string) => void;
};

export const EditModal = (props: Props) => {
    const [value, setValue] = useState<string>(props.editedSetting.value);
    const [error, setError] = useState<string>("");

    const renderValueInput = () => {
        switch (props.editedSetting.type) {
            case SettingType.number:
                return (
                    <TextField
                        type="number"
                        label={I18n.formatMessage({ id: "pages.systemSettingList.editModal.valueLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                setValue(e.currentTarget.value);
                            }
                        }}
                    />
                );
            case SettingType.boolean:
                return (
                    <TextField
                        type="number"
                        label={I18n.formatMessage({ id: "pages.systemSettingList.editModal.valueLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (NumberUtils.isStringNumber(e.currentTarget.value) && ["0", "1", ""].includes(e.currentTarget.value)) {
                                setValue(e.currentTarget.value);
                            }
                        }}
                    />
                );
            case SettingType.string:
            case SettingType.text:
            case SettingType.enum:
                return (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.systemSettingList.editModal.valueLabel" })}
                        fullWidth
                        variant="outlined"
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(e.currentTarget.value);
                        }}
                    />
                );
            case SettingType.datetime:
                try {
                    const parsedDate = value ? DateUtils.format(value, DateFormat.input) : "";
                    return (
                        <TextField
                            label={I18n.formatMessage({ id: "pages.systemSettingList.editModal.valueLabel" })}
                            type="datetime-local"
                            className={`datetime-local ${value ? "datetime-local-filled" : ""}`}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: parsedDate ? (
                                    <InputAdornment position="end" onClick={() => setValue("")}>
                                        <EventBusy />
                                    </InputAdornment>
                                ) : null,
                            }}
                            value={parsedDate}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setValue(e.currentTarget.value ? DateUtils.format(e.currentTarget.value, DateFormat.apiDateTime) : "");
                            }}
                        />
                    );
                } catch {
                    return (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.systemSettingList.editModal.valueLabel" })}
                            fullWidth
                            variant="outlined"
                            value={value}
                            helperText={I18n.formatMessage({ id: "pages.systemSettingList.editModal.dateTimeHelper" })}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setValue(e.currentTarget.value);
                            }}
                        />
                    );
                }
            case SettingType.json:
                return (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.systemSettingList.editModal.valueLabel" })}
                        fullWidth
                        variant="outlined"
                        value={value}
                        error={!!error}
                        helperText={error || undefined}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            try {
                                JSON.parse(e.currentTarget.value);
                                setError("");
                            } catch {
                                setError(I18n.formatMessage({ id: "pages.systemSettingList.editModal.jsonError" }));
                            }
                            setValue(e.currentTarget.value);
                        }}
                    />
                );
            default:
                break;
        }

        return null;
    };

    return (
        <DefaultModal
            title={props.editedSetting.id}
            rightButtonsComponent={
                <Button variant="contained" color="primary" onClick={() => props.onSaveClick(props.editedSetting.id, value)} disabled={!!error}>
                    {I18n.formatMessage({ id: "common.save" })}
                </Button>
            }
            leftButtonsComponent={
                <Button variant="outlined" color="primary" onClick={props.onClose}>
                    {I18n.formatMessage({ id: "common.cancel" })}
                </Button>
            }
            open={props.isVisible}
            onClose={props.onClose}
        >
            <Typography variant="body2" color="textSecondary">
                {!!props.editedSetting.description && (
                    <Typography variant="subtitle1" style={{ maxHeight: "300px", overflowY: "scroll" }}>
                        {StringUtils.doesStringContainHtml(props.editedSetting.description) || StringUtils.doesStringContainLinebreaks(props.editedSetting.description) ? (
                            <span dangerouslySetInnerHTML={{ __html: props.editedSetting.description.replaceAll("\n", "<br />") }}></span>
                        ) : (
                            props.editedSetting.description
                        )}
                    </Typography>
                )}

                <Box mt="30px" />

                {renderValueInput()}
            </Typography>
        </DefaultModal>
    );
};
