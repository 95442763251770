import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { ShippingMethodForm, ShippingMethodFormValues, shippingMethodValidator } from "./ShippingMethodForm";
import { Redirect } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import Prompt from "Components/Prompt";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { ShippingMethod, ShippingMethodInput } from "Api/graphql/admin/types";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { ShippingMethodId } from "Utils/Constants";

type ComponentProps = {
    id: string;
};

type Props = ComponentProps & WithSnackbarProps;

type State = {
    isLoading: boolean;
    shippingMethod: ShippingMethod | null;
};

class ShippingMethodEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        shippingMethod: null,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const fetchedShippingMethod = await Api.getShippingMethodById(this.props.id);
            this.setState({ shippingMethod: fetchedShippingMethod, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    }

    private getValuesForSubmit = (values: ShippingMethodFormValues): ShippingMethodInput | null => {
        const basicData = {
            name: values.name,
            lead: values.lead,
            description: values.description,
            delivery_information: values.delivery_information,
        };
        switch (values.id) {
            case ShippingMethodId.rossmann_delivery:
                return {
                    ...basicData,
                    weight_min: values.weight_min,
                    weight_max: values.weight_max,
                    cart_value_min: values.cart_value_min,
                    is_active: values.is_active,
                    cost: [],
                    cost_terminal: [],
                    roles: values.is_appearance_active
                        ? values.roles.map(r => {
                              return {
                                  ...r,
                                  active_from: r.active_from ? DateUtils.format(r.active_from, DateFormat.apiDateTime) : null,
                                  active_to: r.active_to ? DateUtils.format(r.active_to, DateFormat.apiDateTime) : null,
                              };
                          })
                        : null,
                };
            case ShippingMethodId.mpl_delivery:
            case ShippingMethodId.gls_delivery:
                return {
                    ...basicData,
                    weight_min: values.weight_min,
                    weight_max: values.weight_max,
                    order_max: values.order_max,
                    is_active: values.is_active,
                    cost: values.cost.map(c => {
                        return { cart_limit: c.cart_limit, cost: c.cost };
                    }),
                    cost_terminal: [],
                    cost_time_windows: values.cost_time_windows.map(w => {
                        return {
                            ...w,
                            cost: w.cost.map(c => {
                                return { cart_limit: c.cart_limit, cost: c.cost };
                            }),
                        };
                    }),
                    delivery_informations: (values.delivery_informations ?? []).map(di => {
                        return {
                            ...di,
                            active_from: di.active_from ? DateUtils.format(di.active_from, DateFormat.apiDateTime) : null,
                            active_to: di.active_to ? DateUtils.format(di.active_to, DateFormat.apiDateTime) : null,
                        };
                    }),
                };
            case ShippingMethodId.mpl_pickup:
                return {
                    ...basicData,
                    weight_min: values.weight_min,
                    weight_max: values.weight_max,
                    order_max: values.order_max,
                    is_active: values.is_active,
                    cost: values.cost.map(c => {
                        return { cart_limit: c.cart_limit, cost: c.cost };
                    }),
                    cost_terminal: (values.cost_terminal ?? []).map(ct => {
                        return { cart_limit: ct.cart_limit, cost: ct.cost };
                    }),
                };
            case ShippingMethodId.sameday_easybox:
            case ShippingMethodId.foxpost:
                return {
                    ...basicData,
                    weight_min: values.weight_min,
                    weight_max: values.weight_max,
                    order_max: values.order_max,
                    is_active: values.is_active,
                    cost: values.cost.map(c => {
                        return { cart_limit: c.cart_limit, cost: c.cost };
                    }),
                    cost_terminal: [],
                };
            case ShippingMethodId.gls_pickup:
                return {
                    ...basicData,
                    weight_min: values.weight_min,
                    weight_max: values.weight_max,
                    is_active: values.is_active,
                    cost: values.cost.map(c => {
                        return { cart_limit: c.cart_limit, cost: c.cost };
                    }),
                    cost_terminal: (values.cost_terminal ?? []).map(ct => {
                        return { cart_limit: ct.cart_limit, cost: ct.cost };
                    }),
                };
            case ShippingMethodId.rossmann_shop:
                return {
                    ...basicData,
                    cost: [{ cart_limit: 0, cost: values.price ?? 0 }],
                    cost_terminal: [],
                    is_active: values.is_active,
                    time_limit_next_day_departure: `${values.time_limit_next_day_departure}`,
                };
            default:
                return null;
        }
    };

    private onSubmit = async (values: ShippingMethodFormValues, formikHelpers: FormikHelpers<ShippingMethodFormValues>): Promise<boolean> => {
        try {
            const valuesForSubmit = this.getValuesForSubmit(values);
            if (!valuesForSubmit) return false;
            const shippingMethod = await Api.updateShippingMethod(this.state.shippingMethod!.id, valuesForSubmit);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.shippingMethod.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ shippingMethod });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.shippingMethod.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    public render() {
        const { isLoading, shippingMethod } = this.state;

        if (isLoading) {
            return <Loading />;
        }

        if (!shippingMethod) {
            return <Redirect to={Path.shippingMethodList} />;
        }

        const initialValues: ShippingMethodFormValues = {
            name: shippingMethod.name ?? "",
            lead: shippingMethod.lead,
            description: shippingMethod.description,
            weight_min: shippingMethod.weight_min,
            weight_max: shippingMethod.weight_max,
            cart_value_min: shippingMethod.cart_value_min,
            order_max: shippingMethod.order_max,
            cost: shippingMethod.cost,
            cost_terminal: shippingMethod.cost_terminal,
            is_active: shippingMethod.is_active,
            active_from: shippingMethod.active_from ? DateUtils.format(shippingMethod.active_from, DateFormat.input) : null,
            active_to: shippingMethod.active_to ? DateUtils.format(shippingMethod.active_to, DateFormat.input) : null,
            id: shippingMethod.id,
            price: shippingMethod.cost[0]?.cost ?? null,
            cost_time_windows:
                shippingMethod.cost_time_windows?.map(w => {
                    return {
                        interval_hours: w.interval_hours,
                        cost: w.cost,
                    };
                }) ?? [],
            delivery_information: shippingMethod.delivery_information,
            delivery_informations:
                shippingMethod.delivery_informations?.map(di => {
                    return {
                        active_from: di.active_from ? DateUtils.format(DateUtils.parseISO(di.active_from), DateFormat.input) : null,
                        active_to: di.active_to ? DateUtils.format(DateUtils.parseISO(di.active_to), DateFormat.input) : null,
                        info: di.info,
                    };
                }) ?? [],
            is_appearance_active: shippingMethod.roles && shippingMethod.roles.length > 0 ? true : false,
            roles:
                shippingMethod.roles?.map(r => {
                    return {
                        active_from: r.active_from ? DateUtils.format(DateUtils.parseISO(r.active_from), DateFormat.input) : null,
                        active_to: r.active_to ? DateUtils.format(DateUtils.parseISO(r.active_to), DateFormat.input) : null,
                        vip_levels: r.vip_levels,
                    };
                }) ?? [],
            time_limit_next_day_departure: shippingMethod.time_limit_next_day_departure,
        };

        return (
            <div>
                <Box mt="30px" />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={shippingMethodValidator(FormType.edit)} validateOnBlur={false} enableReinitialize={true}>
                    {props => (
                        <>
                            <ShippingMethodForm formType={FormType.edit} formProps={props} shippingMethod={shippingMethod} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withSnackbar(ShippingMethodEditPage);
