import React, { useEffect, useState } from "react";
import { I18n } from "I18n/I18n";
import { Button, CircularProgress, FormControlLabel, FormHelperText, Radio } from "@bigfish/admin-ui/core";
import { createModal } from "Components/createModal";
import { DeliveryTimeWindow, Order, ShippingMethodType } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { useSnackbar } from "notistack";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import { DateUtils } from "Utils/DateUtils";
import { ScrollableTabsComponent } from "./ScrollableTabsComponent";
import groupBy from "lodash/groupBy";
import format from "date-fns/format";
import { hu } from "date-fns/locale";

const ChangeOrderTimeWindowModal = createModal({ modalWidth: 1280, modalHeight: Math.max(Math.floor(window.innerHeight * 0.8)) });

interface Props {
    order: Order;
    onClose: () => void;
    onSave: (timeWindowId?: number) => void;
    isLoading: boolean;
}

export type QuantifiedItem = {
    id: number;
    quantity: number;
};

type DeliveryTimeWindowShort = { date: string; info: DeliveryTimeWindow[] };

export const ChangeOrderTimeWindowDialog = ({ order, onClose, onSave, isLoading }: Props) => {
    const [isGetTimeWindowsLoading, setIsGetTimeWindowsLoading] = useState<boolean>(false);
    const [timeWindows, setTimeWindows] = useState<DeliveryTimeWindowShort[]>([]);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [selectedTimeWindowId, setSelectedTimeWindowId] = useState<number | null>(null);
    const [sakError, setSakError] = useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    const isOrderSak = order.shipping_method.id === ShippingMethodType.rossmann_delivery;

    useEffect(() => {
        if (order.increment_id === null || !isOrderSak || !order.shipping_address?.zip_code) return;
        (async () => {
            setIsGetTimeWindowsLoading(true);
            try {
                const timeWindowsResult = await Api.getOrderTimeWindows(order.increment_id!);
                const filteredTimeWindows = timeWindowsResult.filter(timeWindow => timeWindow.is_available === true);
                const groupedByDate = groupBy(filteredTimeWindows, "date");
                const groupedTimeWindows = Object.entries(groupedByDate).map(([date, info]) => ({ date, info }));
                setTimeWindows(groupedTimeWindows);
            } catch (error) {
                enqueueSnackbar(I18n.formatMessage({ id: "components.changeOrderTimeWindowDialog.getOrderTimeWindowsError" }), { variant: "error" });
            } finally {
                setIsGetTimeWindowsLoading(false);
            }
        })();
    }, []);

    const getTimeWindowLabel = (date: string, weekday: string) => {
        const cleanDate = DateUtils.parseISO(date);
        const today = isToday(cleanDate);
        const tomorrow = isTomorrow(cleanDate);
        const baseLabel = today ? I18n.formatMessage({ id: "common.today" }) : tomorrow ? I18n.formatMessage({ id: "common.tomorrow" }) : weekday;
        return `${baseLabel} (${format(cleanDate, "LLL", { locale: hu })} ${format(cleanDate, "dd")})`;
    };

    const renderTimeWindowBody = () => {
        const currentTimeWindow = timeWindows[currentTab];
        return (
            <div style={{ overflowY: "scroll", padding: "0 20px" }}>
                {currentTimeWindow.info.map(day => (
                    <>
                        <FormControlLabel
                            key={day.id}
                            checked={selectedTimeWindowId === day.id}
                            onChange={() => {
                                setSelectedTimeWindowId(day.id);
                                if (sakError) {
                                    setSakError(null);
                                }
                            }}
                            control={<Radio />}
                            label={I18n.formatMessage({ id: "components.changeOrderTimeWindowDialog.interval" }, { interval: day.interval })}
                        />
                        <br />
                    </>
                ))}
            </div>
        );
    };

    const onSaveClick = () => {
        if (isOrderSak && selectedTimeWindowId === null) {
            setSakError(I18n.formatMessage({ id: "common.required" }));
            return;
        }

        onSave(selectedTimeWindowId ?? undefined);
    };

    return (
        <ChangeOrderTimeWindowModal
            title={I18n.formatMessage({ id: "components.changeOrderTimeWindowDialog.title" })}
            rightButtonsComponent={
                <>
                    <Button variant="outlined" color="primary" onClick={onClose} style={{ marginRight: "20px" }} disabled={isLoading}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button variant="contained" color="primary" onClick={onSaveClick} disabled={isLoading || (isOrderSak && selectedTimeWindowId === null)}>
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                </>
            }
            open
            onClose={onClose}
        >
            {isGetTimeWindowsLoading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </div>
            ) : (
                <ScrollableTabsComponent
                    tabs={timeWindows.map(tw => ({ id: `${tw.date}`, label: getTimeWindowLabel(tw.date, tw.info[0].weekday), content: renderTimeWindowBody() }))}
                    ariaLabel="ChangeOrderTimeWindowDialogTabs"
                    currentTab={currentTab}
                    changeTab={(_, newTab) => setCurrentTab(newTab)}
                />
            )}
            <FormHelperText className="helper-text-nowrap" error={!!sakError}>
                {sakError}
            </FormHelperText>
        </ChangeOrderTimeWindowModal>
    );
};
