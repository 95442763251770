import { gql } from "@apollo/client";

export const dynamicPageListItemFragment = gql`
    fragment DynamicPageListItem on DynamicPage {
        id
        webshop_path
        script_url
        script_entry_point
        is_active
        zone
    }
`;
