import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import {
    Button,
    FormControl,
    Grid,
    Icon,
    TextField,
    Typography,
    Box,
    InputAdornment,
    InputLabel,
    Select,
    OutlinedInput,
    Chip,
    MenuItem,
    FormHelperText,
} from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import { ClientType, WebsiteNotification, WebsiteNotificationInput } from "Api/graphql/admin/types";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { StatisticsPaper } from "Components/StatisticsPaper";
import RichTextEditor from "Components/Inputs/RichTextEditor/RichTextEditor";
import { EventBusy } from "@bigfish/admin-ui/icons";
import { Constants } from "Utils/Constants";
import { ObjectUtils } from "Utils/ObjectUtils";

export type WebsiteNotificationFormValues = WebsiteNotificationInput;

type Props = {
    formType: FormType;
    formProps: FormikProps<WebsiteNotificationFormValues>;
    websiteNotification?: WebsiteNotification;
};

export const websiteNotificationValidator = (values: WebsiteNotificationFormValues): FormikErrors<WebsiteNotificationFormValues> => {
    const errors: { [key in keyof WebsiteNotificationFormValues]?: string | undefined } = {};

    if (values.active_from && values.active_to) {
        errors.active_to = Validator.endDateCantBeBeforeStart(values.active_from, values.active_to);
        errors.active_from = errors.active_from || Validator.startDateCantBeAfterEnd(values.active_from, values.active_to);
    }

    return Form.cleanupFormikErrors(errors);
};

export const WebsiteNotificationForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.websiteNotificationList);
    }, [history]);

    const handleTargetChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const { value } = event.target;
        props.formProps.setFieldValue("target", typeof value === "string" ? value.split(",") : value);
        props.formProps.setFieldTouched("target", true);
    };

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.content" })} />

                <Box mt="30px" />

                {props.formType === FormType.edit && (
                    <div>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.websiteNotification.form.idLabel" })}
                        </Typography>
                        <Typography variant="subtitle1">{props.websiteNotification?.id}</Typography>
                    </div>
                )}

                <Box mt="30px" />

                <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.websiteNotification.form.nameLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="title">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.websiteNotification.form.titleLabel" })}
                            fullWidth
                            variant="outlined"
                            helperText={Form.getHelperText(meta, "")}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" mb="13px">
                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.websiteNotification.form.messageLabel" })}
                    </Typography>
                </Box>

                <Box mt="15px">
                    <Field name="message">
                        {({ field, meta }: FieldProps) => (
                            <FormControl fullWidth error={!!meta.error}>
                                <RichTextEditor
                                    name="message"
                                    config="websiteNotification"
                                    value={field.value}
                                    onChange={(message: string) => {
                                        props.formProps.setFieldValue("message", message, false);
                                    }}
                                />
                            </FormControl>
                        )}
                    </Field>
                </Box>

                <Box mt="30px" />

                <Field name="target">
                    {({ field, meta }: FieldProps) => (
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>{I18n.formatMessage({ id: "pages.websiteNotification.form.target" })}</InputLabel>
                            <Select
                                labelId="multiselect-input"
                                id="multiselect-input"
                                multiple
                                input={<OutlinedInput id="multiselect-input" label={I18n.formatMessage({ id: "pages.websiteNotification.form.target" })} />}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                renderValue={(selected: ClientType[]) => {
                                    return (
                                        <Box>
                                            {selected.map((value: ClientType) => (
                                                <Chip
                                                    className="multiselect-chip"
                                                    key={value}
                                                    label={Constants.getClientTypeLabel(value)}
                                                    deleteIcon={
                                                        <div
                                                            className="multiselect-delete-icon"
                                                            onMouseDown={event => {
                                                                event.stopPropagation();
                                                                props.formProps.setFieldValue(
                                                                    "target",
                                                                    (props.formProps.values.target ?? []).filter(t => t !== value)
                                                                );
                                                            }}
                                                        >
                                                            <i className="fas fa-times-circle"></i>
                                                        </div>
                                                    }
                                                    onDelete={() => {}}
                                                    clickable={false}
                                                />
                                            ))}
                                        </Box>
                                    );
                                }}
                                error={meta.touched && !!meta.error}
                                {...field}
                                onChange={handleTargetChange}
                            >
                                {ObjectUtils.enumAsArray<ClientType>(ClientType).map(clientType => (
                                    <MenuItem key={clientType} value={clientType}>
                                        {Constants.getClientTypeLabel(clientType)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText className={meta.touched && !!meta.error ? "custom-error-helper" : "helper-text-nowrap"}>{Form.getHelperText(meta, "")}</FormHelperText>
                        </FormControl>
                    )}
                </Field>

                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />

                <Grid container>
                    <Grid item>
                        <Box mr="30px">
                            <Field name="active_from">
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        id="active_from"
                                        label={I18n.formatMessage({ id: "pages.websiteNotification.form.activeFromLabel" })}
                                        type="datetime-local"
                                        className={`datetime-local ${props.formProps.values.active_from ? "datetime-local-filled" : ""}`}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: props.formProps.values.active_from ? (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() => {
                                                        props.formProps.setFieldValue("active_from", "");
                                                        props.formProps.setFieldTouched("active_from", true);
                                                    }}
                                                >
                                                    <EventBusy />
                                                </InputAdornment>
                                            ) : null,
                                        }}
                                        {...field}
                                        value={field.value}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            props.formProps.setTouched({ ...props.formProps.touched, active_from: true });
                                            field.onChange(e);
                                        }}
                                        error={meta.touched && !!meta.error}
                                        helperText={Form.getHelperText(meta, "")}
                                    />
                                )}
                            </Field>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Field name="active_to">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    id="active_to"
                                    label={I18n.formatMessage({ id: "pages.websiteNotification.form.activeToLabel" })}
                                    className={`datetime-local ${props.formProps.values.active_to ? "datetime-local-filled" : ""}`}
                                    type="datetime-local"
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: props.formProps.values.active_to ? (
                                            <InputAdornment
                                                position="end"
                                                onClick={() => {
                                                    props.formProps.setFieldValue("active_to", "");
                                                    props.formProps.setFieldTouched("active_to", true);
                                                }}
                                            >
                                                <EventBusy />
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                    {...field}
                                    value={field.value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.formProps.setTouched({ ...props.formProps.touched, active_to: true });
                                        field.onChange(e);
                                    }}
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, "")}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>
            </PageCard.Container>

            {props.formType === FormType.edit && props.websiteNotification && (
                <StatisticsPaper
                    createdAt={props.websiteNotification.created_at}
                    createdBy={props.websiteNotification.created_by?.name}
                    updatedAt={props.websiteNotification.updated_at}
                    updatedBy={props.websiteNotification.updated_by?.name}
                />
            )}

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
