import { GridView } from "Redux/reducers/gridViewReducer";
import { createAction, ActionsUnion } from "../actions/actionHelpers";

export enum GridConfigActionTypes {
    UPDATE_GRID_CONFIG = "@grid_config/update_grid_config",
}

export const GridConfigActions = {
    updateGridConfig: (gridView: GridView[]) => createAction(GridConfigActionTypes.UPDATE_GRID_CONFIG, { gridView }),
};

export type GridConfigActions = ActionsUnion<typeof GridConfigActions>;
