import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { FunctionalButton } from "Components/FunctionalButton";
import { DynamicPageForm, DynamicPageFormValues } from "./DynamicPageForm";
import { DeleteForever } from "@bigfish/admin-ui/icons";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class DynamicPageCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: DynamicPageFormValues, formikHelpers: FormikHelpers<DynamicPageFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            await Api.createDynamicPage({
                webshop_path: values.webshop_path,
                script_url: values.script_url,
                script_entry_point: values.script_entry_point,
                is_active: values.is_active,
                meta_title: values.meta_title,
                meta_description: values.meta_description,
                og_title: values.og_title,
                og_description: values.og_description,
                og_image: values.og_image,
                zone: "website",
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.dynamicpages.create.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true });
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.dynamicpages.create.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.dynamicPageList} />;
        }

        const initialValues: DynamicPageFormValues = {
            script_entry_point: "",
            script_url: "",
            webshop_path: "",
            is_active: true,
            meta_title: "",
            meta_description: "",
            og_title: "",
            og_description: "",
            og_image: "",
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.divisionList} color="inherit">
                            {I18n.formatMessage({ id: "pages.dynamicpages.breadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.dynamicpages.create.breadcrumb" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <TitleBar title={I18n.formatMessage({ id: "pages.dynamicpages.create.title" })} rightButtonsComponent={<FunctionalButton disabled icon={<DeleteForever />} />} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={true}>
                    {props => (
                        <>
                            <DynamicPageForm formType={FormType.create} formProps={props} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(DynamicPageCreatePage));
