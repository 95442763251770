import React from "react";
import { DateUtils } from "Utils/DateUtils";
import { isAfter } from "date-fns";
import { Icon } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";

type Props = {
    isActive: boolean | null;
    fromDate: string;
};

export const ListItemActive = (props: Props) => {
    if (props.isActive) {
        const fromDateParsed = props.fromDate ? DateUtils.parse(props.fromDate) : null;
        const isVisibleInTheFuture = fromDateParsed && isAfter(fromDateParsed, new Date());

        if (isVisibleInTheFuture) {
            return <Icon className="fa fa-calendar-check" color="secondary" title={I18n.formatMessage({ id: "components.listItemActive.isVisbleInTheFuture" })} />;
        }
    }
    return props.isActive ? (
        <Icon className="fa fa-check-circle" color="secondary" title={I18n.formatMessage({ id: "components.listItemActive.active" })} />
    ) : (
        <Icon className="fa fa-times-circle" color="disabled" title={I18n.formatMessage({ id: "components.listItemActive.inactive" })} />
    );
};
