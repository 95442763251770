import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, Typography, Box, TextField, FormControl, FormControlLabel, Checkbox } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { DynamicPage } from "Api/graphql/admin/types";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { Constants } from "Utils/Constants";

export type DynamicPageFormValues = Omit<DynamicPage, "id" | "__typename" | "zone">;

type Props = {
    formType: FormType;
    formProps: FormikProps<DynamicPageFormValues>;
};

export const DynamicPageForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.dynamicPageList);
    }, [history]);

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "pages.dynamicpages.form.data" })} />
                <Box mt="30px">
                    <Field name="webshop_path" validate={I18nHelpers.formatValidator(Validator.slug)}>
                        {({ field, meta }: FieldProps) => {
                            return (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.dynamicpages.form.webshop_path.label" })}
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    error={meta.touched && !!meta.error}
                                    required
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                />
                            );
                        }}
                    </Field>
                </Box>

                <Box mt="30px">
                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.dynamicpages.form.script_url.label" })}
                    </Typography>
                </Box>
                <Box mt="15px">
                    <Field name="script_url" validate={I18nHelpers.formatValidator(Validator.url)}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.dynamicpages.form.script_url.label" })}
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={meta.touched && !!meta.error}
                                required
                                helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            />
                        )}
                    </Field>
                </Box>

                <Box mt="30px">
                    <Field name="script_entry_point">
                        {({ field, meta }: FieldProps) => {
                            return (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.dynamicpages.form.script_entry_point.label" })}
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    error={meta.touched && !!meta.error}
                                />
                            );
                        }}
                    </Field>
                </Box>

                <Box mt="30px" />

                <Field name="is_active">
                    {({ field, meta }: FieldProps) => (
                        <Box mt="30px">
                            <FormControl fullWidth error={meta.touched && !!meta.error}>
                                <FormControlLabel
                                    control={<Checkbox {...field} checked={field.value} />}
                                    label={I18n.formatMessage({ id: "pages.dynamicpages.form.is_active.label" })}
                                />
                            </FormControl>
                        </Box>
                    )}
                </Field>
            </PageCard.Container>

            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.metaData" })} />
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "common.form.ogImage" })}
                </Typography>
                <Box className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field key={"og_image"} name={"og_image"}>
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="product-category-og_image_url"
                                            name="og_image"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("og_image", value);
                                                props.formProps.setFieldTouched("og_image", true);
                                            }}
                                        />
                                        <CustomFormHelperText meta={meta} />
                                    </div>
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-title-tag-field">
                        <Field key={I18n.formatMessage({ id: "common.form.ogTitle" })} name="og_title" validate={I18nHelpers.formatValidator(Validator.ogTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.ogTitle" })}
                                    fullWidth
                                    variant="outlined"
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogTitleMaxLength }))}
                                    inputProps={{
                                        maxLength: Constants.ogTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-og-description-field">
                        <Field
                            key={I18n.formatMessage({ id: "common.form.ogDescription" })}
                            name="og_description"
                            validate={I18nHelpers.formatValidator(Validator.ogDescriptionMaxLength)}
                        >
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.ogDescription" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogDescriptionMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.ogDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-meta-title-field">
                        <Field key={I18n.formatMessage({ id: "common.form.title" })} name="meta_title" validate={I18nHelpers.formatValidator(Validator.metaTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.title" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.metaTitleMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.metaTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-meta-description-field">
                        <Field
                            key={I18n.formatMessage({ id: "common.form.metaDescription" })}
                            name="meta_description"
                            validate={I18nHelpers.formatValidator(Validator.metaDescriptionMaxLength)}
                        >
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.metaDescription" })}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.metaDescriptionMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.metaDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>
            </PageCard.Container>
            <Box mt="70px" />

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button type="submit" startIcon={<Icon className="fas fa-save" />} variant="contained" color="secondary" disabled={!props.formProps.dirty}>
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
