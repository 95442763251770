export enum PreviewMessageType {
    site_loaded = "site_loaded",
    site_preview_ready = "site_preview_ready",
    site_preview_load_failed = "site_preview_load_failed",
    set_preview_data = "set_preview_data",
}

export type PreviewMessage<T = unknown> = {
    type: PreviewMessageType | string;
    payload: T;
};
