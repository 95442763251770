import { gql } from "@apollo/client";

export const deliveryTimeWindowFragment = gql`
    fragment DeliveryTimeWindow on DeliveryTimeWindow {
        id
        date
        interval
        interval_length
        weekday
        is_available
        cost
        load_percent
    }
`;
