import React from "react";
import { Api } from "Api/Api";
import { TypeColumn, TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Box, Button, Checkbox, Grid, Typography, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Constants } from "Utils/Constants";
import {
    ClientType,
    FulfillmentTypeEnum,
    listOrders_listOrders_data,
    OrderFilters,
    OrderListItem,
    OrderSort,
    OrderSortField,
    OrderStatusEnum,
    orderStatusesQuery_orderStatuses,
    PaymentMethodSelectItem,
    PaymentStatus,
    ShipmentStatusEnum,
    ShipmentStatusSelectItem,
    ShippingMethodSelectItem,
    ShippingMethodType,
} from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { NameWithAvatar } from "Components/NameWithAvatar/NameWithAvatar";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ObjectUtils } from "Utils/ObjectUtils";
import styled from "styled-components";
import { CustomTypeColumn, DataGridUtils } from "Utils/DataGridUtils";
import { IsActiveIcon } from "Components/IsActive";
import startOfDay from "date-fns/startOfDay";
import { GridView } from "Redux/reducers/gridViewReducer";
import { ApplicationState } from "Redux/rootReducer";
import { connect, DispatchProp } from "react-redux";
import { GridConfigActions } from "Redux/actions/gridConfigActions";
import "./styles.css";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";

type ReduxProps = {
    gridConfig: GridView[];
};

type Props = WithSnackbarProps & WithStyles<typeof dataGridStyles> & RouteComponentProps & ReduxProps & DispatchProp;

type AnySelectItem = ShippingMethodSelectItem | PaymentMethodSelectItem | PaymentStatus | ShipmentStatusSelectItem;

type State = {
    orders: listOrders_listOrders_data[];
    limit: number;
    total: number;
    sum: number | null;
    selectedIncrementIds: number[];
    selectShippingMethods: ShippingMethodSelectItem[];
    selectPaymentMethods: PaymentMethodSelectItem[];
    selectPaymentStatuses: PaymentStatus[];
    selectTimeWindowIntervals: string[];
    selectShipmentStatuses: ShipmentStatusSelectItem[];
    selectedFilters?: OrderFilters | null;
    selectedSort?: OrderSort | null;
    orderStatuses: orderStatusesQuery_orderStatuses[];
    refreshIndex: number;
    isResetGridViewModalOpen: boolean;
};

const LIST_NAME = "listOrders";

class OrderListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        orders: [],
        limit: 0,
        total: 0,
        sum: null,
        selectedIncrementIds: [],
        selectShippingMethods: [],
        selectPaymentMethods: [],
        selectPaymentStatuses: [],
        selectTimeWindowIntervals: [],
        selectShipmentStatuses: [],
        selectedFilters: null,
        selectedSort: null,
        orderStatuses: [],
        refreshIndex: 0,
        isResetGridViewModalOpen: false,
    };

    public async componentDidMount(): Promise<void> {
        const sortFunc = (a: AnySelectItem, b: AnySelectItem): number => {
            const getNameOrTitle = (selectItem: AnySelectItem) => {
                switch (selectItem.__typename) {
                    case "ShippingMethod":
                        return (selectItem.name || "").toUpperCase();
                    case "PaymentMethod":
                        return (selectItem.name || "").toUpperCase();
                    case "PaymentStatus":
                    case "ShipmentStatus":
                        return selectItem.title.toUpperCase();
                    default:
                        return "";
                }
            };
            const textA = getNameOrTitle(a);
            const textB = getNameOrTitle(b);
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        };

        try {
            const selectShippingMethods = await Api.listShippingMethodSelectItems({ first: 9999 });
            this.setState({
                selectShippingMethods: [...Array.from(selectShippingMethods.data).sort(sortFunc)],
            });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }

        try {
            const selectPaymentMethods = await Api.listPaymentMethodSelectItems({ first: 9999 });
            this.setState({
                selectPaymentMethods: [...Array.from(selectPaymentMethods.data).sort(sortFunc)],
            });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }

        try {
            const selectPaymentStatuses = await Api.listPaymentStatusSelectItems();
            this.setState({
                selectPaymentStatuses: [...Array.from(selectPaymentStatuses).sort(sortFunc)],
            });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }

        try {
            const intervals = await Api.listTimeWindowIntervalSelectItems();
            this.setState({
                selectTimeWindowIntervals: intervals,
            });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }

        try {
            const shipmentStatuses = await Api.listShipmentStatusSelectItems();
            this.setState({
                selectShipmentStatuses: [...Array.from(shipmentStatuses).sort(sortFunc)],
            });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    }

    private removeOrderIds = (ids: number[]) => {
        this.setState({ selectedIncrementIds: this.state.selectedIncrementIds.filter(id => !ids.includes(id)) });
    };

    private addOrderIds = (ids: number[]) => {
        const newOrderIds = [...this.state.selectedIncrementIds, ...ids];
        this.setState({ selectedIncrementIds: [...new Set(newOrderIds)] });
    };

    private getWidth = (columnName: string) => DataGridUtils.getColumnWidth(this.props.gridConfig, LIST_NAME, columnName);

    private getColumns = (): CustomTypeColumn[] => {
        const columns: CustomTypeColumn[] = [
            {
                name: "selected",
                header: "",
                sortable: false,
                defaultWidth: this.getWidth("selected") ?? 60,
                textAlign: "center",
                filterEditor: () => {
                    const isAllSelected = this.state.orders.every(order => this.state.selectedIncrementIds?.includes(order.id));
                    return (
                        <Checkbox
                            checked={isAllSelected}
                            onChange={() => (isAllSelected ? this.removeOrderIds(this.state.selectedIncrementIds) : this.addOrderIds(this.state.orders.map(o => o.increment_id)))}
                        />
                    );
                },
                filterable: true,
                render: ({ data }: { data: OrderListItem }) => {
                    const isAdded = this.state.selectedIncrementIds?.includes(data.increment_id);

                    return (
                        <div
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox checked={isAdded} onChange={() => (isAdded ? this.removeOrderIds([data.increment_id]) : this.addOrderIds([data.increment_id]))} />
                        </div>
                    );
                },
            },
            {
                name: "increment_id",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.increment_id" }),
                defaultWidth: this.getWidth("increment_id") ?? 130,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "created_at",
                filterEditor: DateFilter,
                dateFormat: "YYYY-MM-DD HH:mm",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.created_at" }),
                defaultWidth: this.getWidth("created_at") ?? 375,
                render: ({ data }: { data: OrderListItem }) => {
                    return <div>{data.created_at ? DateUtils.format(data.created_at, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "user_fullname",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.user_fullname" }),
                defaultFlex: 1,
                minWidth: this.getWidth("user_fullname") ?? 250,
                render: ({ data, value }: { data: OrderListItem; value: string }) => {
                    return <NameWithAvatar identifier={data.increment_id} value={value} />;
                },
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "user_email",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.user_email" }),
                defaultWidth: this.getWidth("user_email") ?? 220,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "card_number",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.card_number" }),
                defaultWidth: this.getWidth("card_number") ?? 220,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "grand_total",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.grand_total" }),
                defaultFlex: 1,
                minWidth: this.getWidth("grand_total") ?? 250,
                filterEditor: NumberFilter,
                render: ({ data }: { data: OrderListItem }) => {
                    return data.grand_total ? I18n.formatCurrency(data.grand_total) : null;
                },
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "is_subscription",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.is_subscription" }),
                defaultWidth: this.getWidth("is_subscription") ?? 130,
                filterDelay: 0,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.yes" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.no" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: OrderListItem }) => <IsActiveIcon value={!!data.order_subscription_id} />,
            },
            {
                name: "order_subscription_id",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.order_subscription_id" }),
                defaultWidth: this.getWidth("order_subscription_id") ?? 150,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "shipping_method_id",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.shipping_method_name" }),
                defaultWidth: this.getWidth("shipping_method_id") ?? 300,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.state.selectShippingMethods.map(sm => {
                        return { id: sm.id, label: sm.name };
                    }),
                },
                render: ({ data }: { data: OrderListItem }) => {
                    return data.shipping_method_name;
                },
            },
            {
                name: "time_window_date",
                filterEditor: DateFilter,
                dateFormat: "YYYY-MM-DD HH:mm",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.time_window_date" }),
                defaultWidth: this.getWidth("time_window_date") ?? 375,
                render: ({ data }: { data: OrderListItem }) => {
                    return <div>{data.time_window_date ? DateUtils.format(data.time_window_date, DateFormat.default) : ""}</div>;
                },
            },
            {
                name: "time_window_interval",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.time_window_interval" }),
                defaultWidth: this.getWidth("time_window_interval") ?? 185,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: this.state.selectTimeWindowIntervals.map(stwi => {
                        return { id: stwi, label: stwi };
                    }),
                },
            },
            {
                name: "delivery_day_expected",
                filterEditor: DateFilter,
                dateFormat: "YYYY-MM-DD HH:mm",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.delivery_day_expected" }),
                defaultWidth: this.getWidth("delivery_day_expected") ?? 375,
                render: ({ data }: { data: OrderListItem }) => {
                    return <div>{data.delivery_day_expected ? DateUtils.format(data.delivery_day_expected, DateFormat.default) : ""}</div>;
                },
            },
            {
                name: "time_window_interval_length",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.time_window_interval_length" }),
                defaultWidth: this.getWidth("time_window_interval_length") ?? 185,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: ["1", "2", "3", "4"].map(twil => {
                        return { id: twil, label: twil };
                    }),
                },
            },
            {
                name: "pickup_point_id",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.pickup_point_id" }),
                defaultWidth: this.getWidth("pickup_point_id") ?? 210,
                render: ({ data }: { data: OrderListItem }) => {
                    return data.shipping_method_name.toLowerCase().startsWith("bolti") ? data.pickup_point?.id : null;
                },
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "payment_method_name",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.payment_method_name" }),
                defaultWidth: this.getWidth("payment_method_name") ?? 300,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.state.selectPaymentMethods.map(spm => {
                        return { id: spm.id, label: spm.name };
                    }),
                },
            },
            {
                name: "payment_status_title",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.payment_status_title" }),
                defaultWidth: this.getWidth("payment_status_title") ?? 300,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.state.selectPaymentStatuses.map(sps => {
                        return { id: sps.id, label: sps.title };
                    }),
                },
            },
            {
                name: "user_agent_type",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.user_agent_type" }),
                filterEditor: SelectFilter,
                defaultWidth: this.getWidth("user_agent_type") ?? 150,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ObjectUtils.enumAsArray<ClientType>(ClientType)
                        .map((type: ClientType) => {
                            return { id: type, label: I18n.formatMessage({ id: `enums.clientType.${type}` }) };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label)),
                },
                sortable: false,
            },
            {
                name: "shipment_statuses",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.shipment_statuses" }),
                defaultWidth: this.getWidth("shipment_statuses") ?? 300,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.state.selectShipmentStatuses.map(sss => {
                        return { id: sss.id, label: sss.title };
                    }),
                },
                render: ({ data }: { data: OrderListItem }) => {
                    return data.shipment_status?.title;
                },
                sortable: false,
            },
            {
                name: "fulfillment",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.fulfillment" }),
                defaultWidth: this.getWidth("fulfillment") ?? 200,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ObjectUtils.enumAsArray<FulfillmentTypeEnum>(FulfillmentTypeEnum)
                        .map((type: FulfillmentTypeEnum) => {
                            return { id: type, label: I18n.formatMessage({ id: `enums.fulfillmentTypeEnum.${type}` }) };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label)),
                },
                render: ({ data }: { data: OrderListItem }) => {
                    return <div>{I18n.formatMessage({ id: `enums.fulfillmentTypeEnum.${data.fulfillment}` })}</div>;
                },
            },
            {
                name: "status",
                header: I18n.formatMessage({ id: "pages.orderList.grid.column.status" }),
                defaultWidth: this.getWidth("status") ?? 300,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ObjectUtils.enumAsArray<OrderStatusEnum>(OrderStatusEnum)
                        .map((type: OrderStatusEnum) => {
                            const count = this.state.orderStatuses.find(os => os.id === type)?.count;
                            return { id: type, label: I18n.formatMessage({ id: `enums.orderStatusEnum.${type}` }) + (count !== undefined && count !== null ? ` (${count})` : "") };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label)),
                },
                render: ({ data }: { data: OrderListItem }) => {
                    return data.status?.title;
                },
                sortable: false,
            },
        ];

        const gridConfig = DataGridUtils.getGridConfigByName(this.props.gridConfig, LIST_NAME);

        if (gridConfig && gridConfig.columns.length === columns.length) {
            const sortedColumns: Array<CustomTypeColumn | undefined> = [];
            gridConfig.columns.forEach(column => sortedColumns.push(columns.find(c => c.name === column.name)));
            return sortedColumns.filter(c => c !== undefined) as CustomTypeColumn[];
        }

        return columns;
    };

    private filterValues: FilterValue[] = [
        { name: "increment_id", operator: "eq", type: "number" },
        { name: "created_at", operator: "inrange", type: "date" },
        { name: "time_window_date", operator: "inrange", type: "date" },
        { name: "delivery_day_expected", operator: "inrange", type: "date" },
        { name: "user_fullname", operator: "contains", type: "string" },
        { name: "user_email", operator: "contains", type: "string" },
        { name: "card_number", operator: "contains", type: "string" },
        { name: "grand_total", operator: "inrange", type: "number" },
        { name: "is_subscription", operator: "eq", type: "select" },
        { name: "order_subscription_id", operator: "eq", type: "number" },
        { name: "shipping_method_id", operator: "eq", type: "select" },
        { name: "time_window_interval", operator: "eq", type: "select" },
        { name: "time_window_interval_length", operator: "eq", type: "select" },
        { name: "pickup_point_id", operator: "eq", type: "number" },
        { name: "payment_method_name", operator: "eq", type: "select" },
        { name: "payment_status_title", operator: "eq", type: "select" },
        { name: "user_agent_type", operator: "eq", type: "select" },
        { name: "shipment_statuses", operator: "eq", type: "select" },
        { name: "fulfillment", operator: "eq", type: "select" },
        { name: "status", operator: "eq", type: "select" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            increment_id: QueryParserType.integer,
            created_at: QueryParserType.dateRange,
            time_window_date: QueryParserType.dateRange,
            delivery_day_expected: QueryParserType.dateRange,
            user_fullname: QueryParserType.string,
            user_email: QueryParserType.string,
            card_number: QueryParserType.string,
            grand_total: QueryParserType.integerRange,
            is_subscription: QueryParserType.boolean,
            order_subscription_id: QueryParserType.integer,
            shipping_method_id: QueryParserType.enum,
            time_window_interval: QueryParserType.enum,
            time_window_interval_length: QueryParserType.enum,
            pickup_point_id: QueryParserType.integer,
            payment_method_name: QueryParserType.enum,
            payment_status_title: QueryParserType.enum,
            user_agent_type: QueryParserType.string,
            shipment_statuses: QueryParserType.enum,
            fulfillment: QueryParserType.enum,
            status: QueryParserType.enum,
        },
        sortField: OrderSortField,
    });

    private sortFieldToSortOrder = {
        [OrderSortField.increment_id]: "increment_id",
        [OrderSortField.created_at]: "created_at",
        [OrderSortField.time_window_date]: "time_window_date",
        [OrderSortField.delivery_day_expected]: "delivery_day_expected",
        [OrderSortField.user_fullname]: "user_fullname",
        [OrderSortField.user_email]: "user_email",
        [OrderSortField.grand_total]: "grand_total",
        [OrderSortField.order_subscription_id]: "order_subscription_id",
        [OrderSortField.shipping_method_name]: "shipping_method_name",
        [OrderSortField.time_window_interval]: "time_window_interval",
        [OrderSortField.time_window_interval_length]: "time_window_interval_length",
        [OrderSortField.pickup_point_id]: "pickup_point_id",
        [OrderSortField.payment_method_name]: "payment_method_name",
        [OrderSortField.payment_status_title]: "payment_status_title",
        [OrderSortField.fulfillment]: "fulfillment",
    };

    private dataSource = async (props: DataGridParams<OrderSort, OrderFilters>): Promise<DataSource<OrderListItem>> => {
        if (props.filters === undefined) {
            this.props.history.push({
                pathname: Path.orderList,
                search: `?page=1&limit=20&created_at=${encodeURI(`{"from":"${DateUtils.format(new Date(), DateFormat.default)} 00:00:00"}`).replaceAll("%22", '"')}`,
            });
        }

        try {
            const cleanFilters = {
                id: props.filters?.id,
                increment_id: props.filters?.increment_id,
                user_id: props.filters?.user_id,
                user_fullname: props.filters?.user_fullname,
                user_email: props.filters?.user_email,
                card_number: props.filters?.card_number ? props.filters.card_number.trim() : undefined,
                shipping_method_id: props.filters?.shipping_method_id ? (`${props.filters?.shipping_method_id}`.split(",") as ShippingMethodType[]) : undefined,
                pickup_point_id: props.filters?.pickup_point_id,
                pickup_point_name: props.filters?.pickup_point_name,
                pickup_point_address: props.filters?.pickup_point_address,
                status: props.filters?.status ? (`${props.filters?.status}`.split(",") as OrderStatusEnum[]) : undefined,
                created_at: props.filters === undefined ? { from: DateUtils.format(startOfDay(new Date()), DateFormat.apiDateTime), to: undefined } : props.filters?.created_at,
                updated_at: props.filters?.updated_at,
                ip: props.filters?.ip,
                user_agent_type: props.filters?.user_agent_type ? (`${props.filters?.user_agent_type}`.split(",") as ClientType[]) : undefined,
                billing_fullname: props.filters?.billing_fullname,
                shipping_fullname: props.filters?.shipping_fullname,
                delivery_day_expected: props.filters?.delivery_day_expected,
                time_window_date: props.filters?.time_window_date,
                time_window_interval: props.filters?.time_window_interval,
                is_in_store: props.filters?.is_in_store,
                store_admin_is_took_over: props.filters?.store_admin_is_took_over,
                grand_total: props.filters?.grand_total
                    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      { min: Number.parseInt(props.filters.grand_total.start, 10), max: Number.parseInt(props.filters.grand_total.end, 10) }
                    : undefined,
                is_subscription: props.filters?.is_subscription,
                order_subscription_id: props.filters?.order_subscription_id,
                time_window_interval_length:
                    props.filters?.time_window_interval === "false"
                        ? null
                        : props.filters?.time_window_interval_length
                        ? Number.parseInt(`${props.filters?.time_window_interval_length}`, 10)
                        : undefined,
                shipment_statuses: props.filters?.shipment_statuses ? (`${props.filters.shipment_statuses}`.split(",") as ShipmentStatusEnum[]) : undefined,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                payment_method_id: props.filters?.payment_method_name ? (`${props.filters?.payment_method_name}`.split(",") as PaymentMethodType[]) : undefined,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                payment_status_id: props.filters?.payment_status_title ? (`${props.filters?.payment_status_title}`.split(",") as PaymentStatusEnum[]) : undefined,
                fulfillment: props.filters?.fulfillment ? (`${props.filters?.fulfillment}`.split(",") as FulfillmentTypeEnum[]) : undefined,
            };

            const result = await Api.listOrders({
                first: props.limit,
                page: props.page,
                filters: cleanFilters,
                sortBy: props.sortBy,
            });

            try {
                const orderStatuses = await Api.orderStatuses(cleanFilters);
                this.setState({ orderStatuses });
            } catch (error) {
                if (error instanceof ApiError) {
                    this.props.enqueueSnackbar(error.message, { variant: "error" });
                }
            }

            this.setState({
                orders: result.data,
                limit: props.limit,
                total: result.paginatorInfo.total,
                sum: result.aggregations.sum_grand_total,
                selectedFilters: cleanFilters,
                selectedSort: props.sortBy,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.orderEdit(rowProps.data.increment_id);
    };

    private selectAllOrders = () => {
        const ids = this.state.orders.map(order => order.increment_id);
        this.setState({ selectedIncrementIds: ids });
    };

    private printSelectedOrders = async () => {
        const pdfUrl = await Api.printOrder(this.state.selectedIncrementIds);
        window.open(pdfUrl, "_new");
    };

    private exportSelectedOrders = async () => {
        const url = await Api.exportOrder(this.state.selectedIncrementIds);
        window.location.assign(url);
    };

    private exportFilteredOrders = async () => {
        try {
            const { url, send_in_email } = await Api.listOrdersExport(this.state.selectedFilters, this.state.selectedSort);
            if (!send_in_email && url) {
                window.location.assign(url);
            } else {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.orderList.exportFilteredSuccess" }), { variant: "success" });
            }
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    };

    render() {
        const { total, sum } = this.state;
        return (
            <div>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs="auto">
                        <Button variant="contained" size="medium" color="secondary" onClick={this.selectAllOrders}>
                            {I18n.formatMessage({ id: "pages.orderList.selectAll" })}
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <Button variant="contained" size="medium" color="secondary" onClick={this.printSelectedOrders} disabled={this.state.selectedIncrementIds.length === 0}>
                            {I18n.formatMessage({ id: "pages.orderList.print" })}
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <Button variant="contained" size="medium" color="secondary" onClick={this.exportSelectedOrders} disabled={this.state.selectedIncrementIds.length === 0}>
                            {I18n.formatMessage({ id: "pages.orderList.export" })}
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <Button variant="contained" size="medium" color="secondary" onClick={this.exportFilteredOrders}>
                            {I18n.formatMessage({ id: "pages.orderList.exportFiltered" })}
                        </Button>
                    </Grid>
                    {total > 0 && !!sum && (
                        <SumGrid item xs>
                            <Typography variant="subtitle1">{I18n.formatMessage({ id: "pages.orderList.sum" }, { count: total, sum: I18n.formatCurrency(sum) })}</Typography>
                        </SumGrid>
                    )}
                </Grid>
                <Box mt="30px" />
                <DataGrid
                    key={this.state.refreshIndex}
                    className={this.props.classes.dataGrid}
                    urlQueryParser={this.urlQueryParser}
                    rowHeight={50}
                    style={{ minHeight: 800 }}
                    dataSource={this.dataSource}
                    filterValues={this.filterValues}
                    columns={this.getColumns()}
                    rowLink={this.getRowLink}
                    activeCell={null}
                    sortFieldToSortOrder={this.sortFieldToSortOrder}
                    onColumnOrderChange={(columnOrder: string[]) =>
                        this.props.dispatch(GridConfigActions.updateGridConfig(DataGridUtils.getNewGridConfigByOrder(this.props.gridConfig, LIST_NAME, columnOrder)))
                    }
                    onColumnResize={(
                        obj:
                            | {
                                  column: TypeColumn;
                                  width?: number;
                                  flex?: number;
                              }
                            | {
                                  column: TypeColumn;
                                  width?: number;
                              }
                    ) => {
                        // If it's an unflexed column we take .width, otherwise .flex
                        const newWidth = obj.width || ("flex" in obj && obj.flex && obj.flex !== obj.column.minWidth ? obj.flex : 0);

                        if (obj.column.name && newWidth) {
                            this.props.dispatch(
                                GridConfigActions.updateGridConfig(DataGridUtils.getNewGridConfigByWidth(this.props.gridConfig, LIST_NAME, obj.column.name, newWidth))
                            );
                        }
                    }}
                />

                <Box mt="15px" />

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <span onClick={() => this.setState({ isResetGridViewModalOpen: true })} className="order-list-faux-anchor">
                        {I18n.formatMessage({ id: "pages.orderList.resetGridView" })}
                    </span>
                </div>

                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.orderList.resetModal.title" })}
                    description={I18n.formatMessage({ id: "pages.orderList.resetModal.description" })}
                    isVisible={this.state.isResetGridViewModalOpen}
                    onClose={() => this.setState({ isResetGridViewModalOpen: false })}
                    onFunctionClick={() => {
                        const newGridConfig = DataGridUtils.getGridConfigWithoutListName(this.props.gridConfig, LIST_NAME);
                        this.props.dispatch(GridConfigActions.updateGridConfig(newGridConfig));
                        this.setState({ refreshIndex: this.state.refreshIndex + 1, isResetGridViewModalOpen: false });
                    }}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.yes" })}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({ gridConfig: state.gridConfig.gridViews });

export default connect(mapStateToProps)(withRouter(withSnackbar(withStyles(dataGridStyles, { withTheme: true })(OrderListDataGrid))));

const SumGrid = styled(Grid)`
    display: flex;
    justify-content: flex-end;
`;
