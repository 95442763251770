import { ReduxAction } from "Redux/actions/reduxAction";
import { GridConfigActionTypes } from "../actions/gridConfigActions";

export type ColumnView = {
    name: string;
    width?: number;
};

export type GridView<T = ColumnView[]> = {
    name: string;
    columns: T;
};

export type GridConfigReducerType = {
    gridViews: GridView[];
};

const initialState: GridConfigReducerType = {
    gridViews: [],
};

export function gridConfigReducer(state: GridConfigReducerType = initialState, action: ReduxAction): GridConfigReducerType {
    switch (action.type) {
        case GridConfigActionTypes.UPDATE_GRID_CONFIG:
            return { ...state, gridViews: action.payload.gridView };
        default:
            return state;
    }
}
