import { gql } from "@apollo/client";

export const dynamicPageFragment = gql`
    fragment DynamicPage on DynamicPage {
        id
        webshop_path
        script_url
        script_entry_point
        is_active
        zone
        meta_title
        meta_description
        og_title
        og_description
        og_image
    }
`;
