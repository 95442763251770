/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateRangeFilter, OrderDirection } from "Api/graphql/admin/types";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { ObjectUtils } from "Utils/ObjectUtils";
import { IntervalFilterValue } from "./DataGrid";
import { DateFilterValue, IntegerRangeFilterValue } from "./DataGridUtils";
import { DataGridParams, ParsedUrlQueryParams, UrlQueryParser } from "./UrlQueryParser";

export enum QueryParserType {
    float = "float",
    integer = "integer",
    boolean = "boolean",
    enum = "enum",
    string = "string",
    dateRange = "dateRange",
    integerRange = "integerRange",
}

export type QueryParserDescriptor<SortField extends Object> = {
    sortField?: SortField;
    filters: {
        [key: string]: QueryParserType;
    };
};

export type UrlQueryObjectValue = string[] | string | number | boolean | null | undefined;

export type UrlQueryObject = { [key: string]: UrlQueryObjectValue };

export class DataGridUrlQueryParser<SortField extends Object, Filters extends { [key: string]: any }> extends UrlQueryParser<
    { field: SortField; direction: OrderDirection },
    Filters
> {
    constructor(private descriptor: QueryParserDescriptor<SortField>) {
        super();
    }

    protected getParams(queryParams: ParsedUrlQueryParams): { sortBy?: { field: SortField; direction: OrderDirection }; filters?: Filters } {
        let filters: Filters | undefined = Object.keys(this.descriptor.filters).reduce((prevState, current: string) => {
            let value = undefined;
            switch (this.descriptor.filters[current]) {
                case QueryParserType.float:
                    value = UrlQueryParser.getFloat(queryParams, current);
                    break;
                case QueryParserType.integer:
                    value = UrlQueryParser.getInteger(queryParams, current);
                    break;
                case QueryParserType.boolean:
                    value = UrlQueryParser.getBoolean(queryParams, current);
                    break;
                case QueryParserType.enum:
                    value = UrlQueryParser.getString(queryParams, current);
                    break;
                case QueryParserType.string:
                    value = UrlQueryParser.getString(queryParams, current);
                    break;
                case QueryParserType.dateRange:
                    value = UrlQueryParser.getDateRangeFilter(queryParams, current);
                    break;
                case QueryParserType.integerRange:
                    value = UrlQueryParser.getIntegerRangeFilter(queryParams, current);
                    break;
                default:
                    return prevState;
            }

            // @ts-ignore
            prevState[current] = value;
            return prevState;
        }, {} as Filters);

        if (!ObjectUtils.hasDefinedValue(filters)) {
            filters = undefined;
        }

        const direction = UrlQueryParser.getEnum<OrderDirection>(queryParams, "direction", OrderDirection);
        const field = UrlQueryParser.getEnum<SortField>(queryParams, "sortField", this.descriptor.sortField);
        let sortBy: { field: SortField; direction: OrderDirection } | undefined = undefined;
        if (direction && field) {
            sortBy = { direction, field };
        }

        return { filters, sortBy };
    }

    private static toDateFilterValue(dateRange: DateRangeFilter | null | undefined): DateFilterValue | undefined {
        if (!dateRange) return undefined;

        // @ts-ignore
        const from = dateRange.from ?? dateRange.start;
        // @ts-ignore
        const to = dateRange.to ?? dateRange.end;

        if (!from && !to) return undefined;

        return {
            from: from ? DateUtils.format(from, DateFormat.apiDateTime) : undefined,
            to: to ? DateUtils.format(to, DateFormat.apiDateTime) : undefined,
        };
    }

    private static toRangeFilterValue(integerRange: IntegerRangeFilterValue | null | undefined): IntervalFilterValue | undefined {
        if (!integerRange || (!integerRange.start && !integerRange.end)) {
            return undefined;
        }
        return {
            start: integerRange.start ? `${integerRange.start}` : undefined,
            end: integerRange.end ? `${integerRange.end}` : undefined,
        };
    }

    public getUrlQueryObject(dataGridParams: DataGridParams<{ field: SortField; direction: OrderDirection }, Filters>): UrlQueryObject {
        const filters = Object.keys(this.descriptor.filters).reduce((prevState, current) => {
            if (!dataGridParams.filters) {
                return prevState;
            }

            // @ts-ignore
            let value = dataGridParams.filters[current];
            if (this.descriptor.filters[current] === QueryParserType.dateRange) {
                // @ts-ignore
                const dateFilterValue = DataGridUrlQueryParser.toDateFilterValue(value);
                value = dateFilterValue ? JSON.stringify(dateFilterValue) : undefined;
            } else if (this.descriptor.filters[current] === QueryParserType.integerRange) {
                // @ts-ignore
                const integerFilterValue = DataGridUrlQueryParser.toRangeFilterValue(value);
                value = integerFilterValue ? JSON.stringify(integerFilterValue) : undefined;
            }

            // @ts-ignore
            prevState[current] = value;
            return prevState;
        }, {});

        return {
            page: dataGridParams.page,
            limit: dataGridParams.limit,
            ...filters,

            // @ts-ignore
            sortField: dataGridParams.sortBy?.field,
            direction: dataGridParams.sortBy?.direction,
        };
    }

    public static getStringArray(params: ParsedUrlQueryParams, key: string): string[] | undefined {
        const value = params[key];
        const returned = Array.isArray(value) ? (value as number[]).map(v => `${v}`) : undefined;
        return returned;
    }

    public static getIntegerArray(params: ParsedUrlQueryParams, key: string): number[] | undefined {
        const value = params[key];
        return typeof value === "string" ? value.split(", ").map(v => Number.parseInt(`${v}`, 10)) : undefined;
    }

    public static getEnum<T>(params: ParsedUrlQueryParams, key: string, enumObject: Object): T | undefined {
        const value = DataGridUrlQueryParser.getString(params, key);
        if (value && ObjectUtils.isEnumContains(enumObject, value)) {
            return (value as unknown) as T;
        }
        return undefined;
    }

    public static getInterval(params: ParsedUrlQueryParams, key: string): IntervalFilterValue | undefined {
        try {
            return JSON.parse(decodeURIComponent(DataGridUrlQueryParser.getString(params, key) ?? ""));
        } catch {
            return;
        }
    }
}
