import { createStyles } from "@bigfish/admin-ui/core";

export const dashboardStyles = () =>
    createStyles({
        container: {
            paddingTop: "3%",
        },
        content: {
            textAlign: "center",
        },
        title: {
            fontSize: 32,
            lineHeight: "56px",
            fontWeight: 700,
        },
        subtitle: {
            fontSize: 14,
        },
        greenPercent: {
            color: "#36B37E",
            marginLeft: "5px",
        },
        redPercent: {
            color: "#FF5630",
            marginLeft: "5px",
        },
        text: {
            fontSize: 20,
        },
    });
