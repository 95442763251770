import { gql } from "@apollo/client";
import { dynamicPageFragment } from "../fragments/dynamicPageFragment";

export const updateDynamicPageMutation = gql`
    mutation updateDynamicPage($id: Int!, $input: DynamicPageInput!, $csrf: String) {
        updateDynamicPage(id: $id, input: $input, csrf: $csrf) {
            ...DynamicPage
        }
    }
    ${dynamicPageFragment}
`;
