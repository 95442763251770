import { gql } from "@apollo/client";
import { userAddressSmallFragment } from "./userAddressSmallFragment";
import { orderItemFragment } from "./orderItemFragment";
import { adminNoteFragment } from "./adminNoteFragment";
import { paymentTransactionFragment } from "./paymentTransactionFragment";
import { giftCardPaymentFragment } from "./giftCardPaymentFragment";
import { orderInvoiceFragment } from "./orderInvoiceFragment";

export const orderFragment = gql`
    fragment Order on Order {
        id
        increment_id
        created_at
        contact_fullname
        email
        phone_number
        user {
            email
            rossmann_plus_profile {
                card_number
            }
        }
        status {
            id
            title
        }
        shipping_method {
            id
            name
            cost_time_windows {
                interval_hours
            }
        }
        delivery_day_expected
        delivery_time_window {
            date
            weekday
            interval
            interval_length
        }
        shipping_address {
            ...UserAddressSmall
        }
        billing_address {
            ...UserAddressSmall
        }
        payment_method {
            id
            name
        }
        grand_total
        tax_total
        payment {
            status {
                id
                title
            }
            transaction_latest {
                id
                anum
            }
            amount_paid
            amount_refunded
            transactions {
                ...PaymentTransaction
            }
        }
        admin_notes {
            ...AdminNote
        }
        items {
            ...OrderItem
        }
        shipment {
            status
            delivered_at
            tracking_url
            tracking_numbers
            sent_at
            delivery_in_progress_at
            delivery_day_expected
            can_retry
        }
        invoice_status {
            title
        }
        shipping_note
        grand_total_netto
        invoices {
            ...OrderInvoice
        }
        pickup_point {
            id
            address
        }
        can_be_modified
        can_be_deleted
        evo_sent_at
        user_agent_type
        app_version
        no_package
        gift_cards {
            ...GiftCardPayment
        }
        gift_card_payment
        can_storno
        can_refund
        is_timewindow_changeable
    }
    ${adminNoteFragment}
    ${userAddressSmallFragment}
    ${orderItemFragment}
    ${paymentTransactionFragment}
    ${giftCardPaymentFragment}
    ${orderInvoiceFragment}
`;
