import { Reducer } from "redux";
import { persistCombineReducers } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import { PersistPartial } from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { AuthReducerType, authReducer } from "./reducers/authReducer";
import PackageJSON from "../../package.json";
import { gridConfigReducer, GridConfigReducerType } from "./reducers/gridViewReducer";

export interface ApplicationState {
    auth: AuthReducerType;
    gridConfig: GridConfigReducerType;
}

const config: PersistConfig<ApplicationState> = {
    key: PackageJSON.name,
    storage,
    debug: process.env.REACT_APP_DEBUG_MODE === "true",
    whitelist: ["auth", "gridConfig"],
};

export const rootReducer: Reducer<ApplicationState & PersistPartial> = persistCombineReducers<ApplicationState>(config, {
    auth: authReducer,
    gridConfig: gridConfigReducer,
});
