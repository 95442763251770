import { gql } from "@apollo/client";
import { deliveryTimeWindowFragment } from "../fragments/deliveryTimeWindowFragment";

export const orderTimeWindowsQuery = gql`
    query orderTimeWindows($increment_id: Int!) {
        orderTimewindows(increment_id: $increment_id) {
            ...DeliveryTimeWindow
        }
    }
    ${deliveryTimeWindowFragment}
`;
