import React from "react";
import { Button, Icon } from "@bigfish/admin-ui/core";
import { PageLayout } from "Components/PageLayout";
import { I18n } from "I18n/I18n";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import DynamicPageDataGrid from "./DynamicPageDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";

type Props = RouteComponentProps;

class DynamicPageListPage extends React.Component<Props> {
    private onAddClick = (): void => {
        this.props.history.push(Path.dynamicPageCreate);
    };

    public render() {
        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.dynamicpages.title" })}
                    rightButtonsComponent={
                        <Button startIcon={<Icon className="fa fa-plus-circle" />} variant="contained" size="medium" color="secondary" onClick={this.onAddClick}>
                            {I18n.formatMessage({ id: "pages.dynamicpages.add" })}
                        </Button>
                    }
                />
                <PageCard.Container>
                    <DynamicPageDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withRouter(DynamicPageListPage);
